import { createSlice } from "@reduxjs/toolkit";
import { API_ENDPOINTS } from "../client/api.constants";

import { postAPICall, getAPICall } from "../client/api.client";

const initialState = {
  users: {
    isLoading: false,
  },
  update_user: {
    isLoading: false,
  },
  export_user: {
    isLoading: false,
  },
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    users: (state, action) => {
      state.users = action.payload;
    },
    update_user: (state, action) => {
      state.update_user = action.payload;
    },
    export_user: (state, action) => {
      state.export_user = action.payload;
    },
    reset: (state, action) => {
      state.users = {
        isLoading: false,
      };
      state.update_user = {
        isLoading: false,
      };
    },
  },
});

export const getUsersAsync = (params) => async (dispatch) => {
  try {
    dispatch(users({ isLoading: true }));
    const result = await getAPICall(API_ENDPOINTS.USERS, params);
    dispatch(users({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(users({ isLoading: false }));
  }
};
export const exportUsersAsync = (params) => async (dispatch) => {
  try {
    dispatch(export_user({ isLoading: true }));
    const result = await getAPICall(API_ENDPOINTS.EXPORTUSERS, params, true);
    dispatch(export_user({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(export_user({ isLoading: false }));
  }
};
export const updateUserAsync = (params) => async (dispatch) => {
  try {
    dispatch(update_user({ isLoading: true }));
    const result = await postAPICall(API_ENDPOINTS.UPDATEUSER, params);
    dispatch(update_user({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(update_user({ isLoading: false }));
  }
};

export const resetAsync = () => async (dispatch) => {
  dispatch(reset(null));
};

export const { users, update_user, reset, export_user } = usersSlice.actions;
export const usersResponse = (state) => state.users.users;
export const exportUsersResponse = (state) => state.users.export_user;
export const updateUserResponse = (state) => state.users.update_user;
export default usersSlice.reducer;
