import React, { useEffect, useState } from "react";

import { Helmet } from "react-helmet";
import Topbar from "../../components/topbar";
import MainSidebar from "../../components/main-sidebar";
import Footer from "../../components/footer";
import PageHeader from "../../components/page-header";
import Loader from "../../components/loader";
import { useAuth } from "../../utils/auth_context";
import { useNavigate } from "react-router-dom";

export const BackendLayout = (WrappedComponent) => {
  function HOC(props) {
    const [isLoading, setLoading] = useState(false);
    const { isAuthenticated } = useAuth();
    const navigate = useNavigate();

    const setLoadingState = (isComponentLoading) => {
      setLoading(isComponentLoading);
    };

    useEffect(() => {
      window.scrollTo(0, 0);
      if (!isAuthenticated) {
        navigate("/");
      }
    }, []);

    return (
      <React.Fragment>
        <Helmet>
          <title>TCDL</title>
          <style></style>
        </Helmet>
        <Topbar />
        <MainSidebar />
        <PageHeader />
        <div className="main-content">
          <WrappedComponent {...props} setLoading={setLoadingState} />
          <Footer />
          <Loader show={isLoading} />
        </div>
      </React.Fragment>
    );
  }

  return HOC;
};
export default BackendLayout;
