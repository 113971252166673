import React, { useEffect, useState, useRef } from "react";
import "./index.scss";
import {
  Row,
  Col,
  Card,
  Media,
  InputGroup,
  Form,
  Image,
  Modal,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import BackendLayout from "../../layouts/backend-layout";
import { useSelector, useDispatch } from "react-redux";
import {
  getConversationsAsync,
  getConversationsResponse,
  getQuestionsAsync,
  getQuestionsResponse,
  giveAnswerResponse,
  giveAnswerAsync,
  resetAsync,
} from "../../api/slices/questions.slice";
import { toast } from "react-toastify";
import { getUser } from "../../utils/utils";
import { API_ENDPOINTS } from "../../api/client/api.constants";
import MicRecorder from "mic-recorder-to-mp3";

const Mp3Recorder = new MicRecorder({
  bitRate: 128,
  prefix: "data:audio/wav;base64,",
});

function Questions(props) {
  const { setLoading } = props;
  const dispatch = useDispatch();
  const _getConversationsResponse = useSelector(getConversationsResponse);
  const _getQuestionsResponse = useSelector(getQuestionsResponse);
  const _giveAnswerResponse = useSelector(giveAnswerResponse);
  const [conversations, setConversations] = useState([]);
  const [conversation_id, setConversationId] = useState();
  const [questions, setQuestions] = useState([]);
  const [user, setUser] = useState(getUser());
  const messagesRef = useRef(null);
  const [image, setImage] = useState();
  const [answer, setAnswer] = useState("");
  const [isRecording, setRecording] = useState(false);
  const [showImageModel, setShowImageModel] = React.useState(false);
  const handleImageModelClose = () => setShowImageModel(false);
  const handleImageModelShow = (request) => {
    setImage(request);
    setShowImageModel(true);
  };
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [intervalId, setIntervalId] = useState();

  const [showVoiceRecoderModel, setShowVoiceRecoderModel] =
    React.useState(false);
  const handleVoiceRecoderModelClose = () => setShowVoiceRecoderModel(false);
  const handleVoiceRecoderModelShow = () => {
    setShowVoiceRecoderModel(true);
  };

  const [permissionGranted, setPermissionGranted] = useState(null);

  useEffect(() => {
    dispatch(getConversationsAsync({}));
  }, []);

  useEffect(() => {
    setLoading(_getConversationsResponse.isLoading);
    if (
      _getConversationsResponse.response &&
      _getConversationsResponse.response.status === 200
    ) {
      setConversations(_getConversationsResponse.response.data.conversations);
    } else {
      toast.error(_getConversationsResponse.response?.message);
    }
  }, [_getConversationsResponse]);

  useEffect(() => {
    setLoading(_getQuestionsResponse.isLoading);
    if (
      _getQuestionsResponse.response &&
      _getQuestionsResponse.response.status === 200
    ) {
      setQuestions(_getQuestionsResponse.response.data.questions);
      setTimeout(() => {
        if (messagesRef.current) {
          const lastChildElement = messagesRef.current.lastElementChild;
          lastChildElement.scrollIntoView({ behavior: "instant" });
        }
      }, 0);
      setConversations(
        conversations.map((i) => {
          if (i.id == conversation_id) {
            return { ...i, unread: 0 };
          }
          return i;
        })
      );
    } else {
      toast.error(_getQuestionsResponse.response?.message);
    }
  }, [_getQuestionsResponse]);

  useEffect(() => {
    setLoading(_giveAnswerResponse.isLoading);
    if (
      _giveAnswerResponse.response &&
      _giveAnswerResponse.response.status === 200
    ) {
      setAnswer("");
      setQuestions([]);
      dispatch(getQuestionsAsync({ conversation_id }));
    } else {
      toast.error(_giveAnswerResponse.response?.message);
    }
  }, [_giveAnswerResponse]);

  useEffect(() => {
    if (isRecording) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      const intervalId = setInterval(() => {
        if (seconds === 59) {
          setSeconds(0);
          setMinutes((prevMinutes) => prevMinutes + 1);
        } else {
          setSeconds((prevSeconds) => prevSeconds + 1);
        }
      }, 1000);
      setIntervalId(intervalId);
    } else {
      clearInterval(intervalId);
      setSeconds(0);
      setMinutes(0);
    }
  }, [isRecording]);

  const onConversationClick = (conversation_id) => {
    setConversationId(conversation_id);
    dispatch(getQuestionsAsync({ conversation_id }));
  };

  const onGiveAnswer = (e) => {
    e.preventDefault();
    console.log(user);
    if (conversation_id) {
      if (answer != "") {
        dispatch(
          giveAnswerAsync({
            answer,
            conversation_id,
            type: "TEXT",
          })
        );
      } else {
        toast.error("Please enter answer");
      }
    } else {
      toast.error("Please select conversation first.");
    }
  };

  const checkMicrophonePermission = async () => {
    try {
      const permissionStatus = await navigator.permissions.query({
        name: "microphone",
      });
      if (permissionStatus.state === "granted") {
        setPermissionGranted(true);
      } else if (permissionStatus.state === "prompt") {
        // Permission not determined, prompt user for permission
        const permissionResult = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        if (permissionResult) {
          setPermissionGranted(true);
          permissionResult.getTracks().forEach((track) => track.stop());
        } else {
          setPermissionGranted(false);
        }
      } else {
        // Permission denied or revoked
        setPermissionGranted(false);
      }
    } catch (error) {
      toast.error("Please give microphone permission");
    }
  };

  const askForMicrophonePermission = async () => {
    try {
      const permissionResult = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      if (permissionResult) {
        setPermissionGranted(true);
        permissionResult.getTracks().forEach((track) => track.stop());
      } else {
        setPermissionGranted(false);
      }
    } catch (error) {
      toast.error("Please give microphone permission");
      setPermissionGranted(false);
    }
  };

  const handlePermissionRequest = () => {
    if (permissionGranted === null) {
      checkMicrophonePermission();
    } else if (permissionGranted === false) {
      askForMicrophonePermission();
    } else if (permissionGranted) {
      if (conversation_id) {
        if (!isRecording) {
          Mp3Recorder.start()
            .then(() => {
              setRecording(true);
            })
            .catch((e) => {
              console.error(e);
              toast.error("Error while start recording");
            });
        } else {
          Mp3Recorder.stop()
            .getMp3()
            .then(([buffer, blob]) => {
              setRecording(false);
              const file = new File(buffer, `${Date.now()}.mp3`, {
                type: blob.type,
                lastModified: Date.now(),
              });
              dispatch(
                giveAnswerAsync({
                  conversation_id,
                  type: "VOICE",
                  media: file,
                })
              );
            })
            .catch((e) => {
              console.log(e);
              toast.error("Error while stop recording");
            });
        }
      } else {
        toast.error("Please select conversation first.");
      }
    }
  };

  return (
    <React.Fragment>
      <Row>
        <Col md="12">
          <Card>
            <Card.Header>Questions</Card.Header>
            <Card.Body className="no-padding">
              <Row>
                <Col md="4">
                  <div
                    className="chat-contacts padding-20"
                    style={{ maxHeight: "400px", overflow: "scroll" }}
                  >
                    <ul className="list-unstyled sidebar-contact-list">
                      {conversations.map((item, i) => (
                        <Media key={i} as="li" className="clearfix">
                          <Link
                            onClick={(e) => {
                              e.preventDefault();
                              onConversationClick(item.id);
                            }}
                            style={{ width: "100%" }}
                            className="media-box"
                          >
                            <span className="float-left">
                              <img
                                src={
                                  item.user.gender == "MALE"
                                    ? "/assets/img/avtar-2.png"
                                    : "/assets/img/avtar-1.png"
                                }
                                alt="user"
                                className="media-box-object rounded-circle"
                                width={50}
                              />
                            </span>
                            <span className="media-box-body">
                              <span className="media-box-heading">
                                <strong>
                                  {item.user.first_name} {item.user.middle_name}{" "}
                                  {item.user.last_name}
                                </strong>
                                <br />
                                <small className="text-muted">
                                  {item.user.subscription[0].plan.title}
                                </small>

                                {item.unread > 0 && (
                                  <>
                                    <br />
                                    <small
                                      className="text-muted"
                                      style={{ display: "flex" }}
                                    >
                                      <b style={{ color: "red" }}>
                                        {" "}
                                        {item.unread} New Message
                                      </b>
                                    </small>
                                  </>
                                )}
                              </span>
                            </span>
                          </Link>
                        </Media>
                      ))}
                    </ul>
                  </div>
                </Col>
                <Col md="8" style={{ height: "400px", overflow: "scroll" }}>
                  <div className="chat-room padding-20">
                    <ul ref={messagesRef} className="chat-list list-unstyled">
                      {questions.map((item, i) => {
                        if (user.data.id == item.user.id) {
                          return (
                            <li className="clearfix chat-element right media">
                              <div className="media-body text-right float-left">
                                <div className="speech-box">
                                  <strong className="float-left">
                                    TCDL Admin
                                  </strong>
                                  <small className="text-right text-primary">
                                    {item.ago}
                                  </small>
                                  <p>
                                    {item.message_type == "TEXT_IMAGE" && (
                                      <img
                                        onClick={() => {
                                          handleImageModelShow(item);
                                        }}
                                        style={{
                                          width: "150px",
                                          height: "150px",
                                          objectFit: "contain",
                                        }}
                                        alt="#"
                                        src={`${API_ENDPOINTS.IMAGE}${item.media}`}
                                      />
                                    )}
                                  </p>

                                  <p>
                                    {item.message_type == "VOICE" && (
                                      <audio
                                        style={{ width: "100%" }}
                                        src={`${API_ENDPOINTS.IMAGE}${item.media}`}
                                        controls="controls"
                                      />
                                    )}
                                  </p>
                                  <p className="margin-b-0 text-left">
                                    {item.content}
                                  </p>
                                  <small className="text-info">
                                    {item.created_at}
                                  </small>
                                </div>
                              </div>
                              <Link to="#be" className="float-right">
                                <img
                                  src="/assets/img/avtar-2.png"
                                  alt=""
                                  className="rounded-circle"
                                />
                              </Link>
                            </li>
                          );
                        } else {
                          return (
                            <li className="clearfix chat-element media">
                              <Link to="#be" className="float-left">
                                <img
                                  src="/assets/img/avtar-2.png"
                                  alt=""
                                  className="rounded-circle"
                                />
                              </Link>
                              <div className="media-body ">
                                <div className="speech-box">
                                  <small className="float-right text-primary">
                                    {item.ago}
                                  </small>
                                  <strong>
                                    {item.user.first_name}{" "}
                                    {item.user.middle_name}{" "}
                                    {item.user.last_name}
                                  </strong>
                                  <p>
                                    {item.message_type == "TEXT_IMAGE" && (
                                      <img
                                        onClick={() => {
                                          handleImageModelShow(item);
                                        }}
                                        style={{
                                          width: "150px",
                                          height: "150px",
                                          objectFit: "contain",
                                        }}
                                        alt="#"
                                        src={`${API_ENDPOINTS.IMAGE}${item.media}`}
                                      />
                                    )}
                                  </p>
                                  <p className="margin-b-0">{item.content}</p>
                                  <small className="text-info">
                                    {item.created_at}
                                  </small>
                                </div>
                              </div>
                            </li>
                          );
                        }
                      })}
                    </ul>
                  </div>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              <InputGroup>
                <Form.Control
                  type="text"
                  value={answer}
                  onChange={(e) => setAnswer(e.target.value)}
                  placeholder="Type your message here..."
                />
                <p
                  style={{
                    marginRight: "10px",
                    marginLeft: "10px",
                    marginTop: "10px",
                  }}
                  className={`margin-b-0 text-left ${
                    isRecording ? "d-block" : "d-none"
                  }`}
                >
                  {minutes < 10 ? `0${minutes}` : minutes}
                  {" : "}
                  {seconds < 10 ? `0${seconds}` : seconds}
                </p>

                <span className="input-group-btn">
                  <button
                    className="btn btn-primary"
                    style={{ marginRight: "10px" }}
                    onClick={handlePermissionRequest}
                  >
                    <i
                      className={`${
                        isRecording ? "fa-microphone-slash" : "fa fa-microphone"
                      }`}
                    />
                  </button>
                </span>
                <span className="input-group-btn">
                  <button className="btn btn-primary" onClick={onGiveAnswer}>
                    <i className="fa fa-send" />
                  </button>
                </span>
              </InputGroup>
            </Card.Footer>
          </Card>
        </Col>
      </Row>

      <Modal show={showImageModel} onHide={handleImageModelClose}>
        <Modal.Header closeButton>
          <Modal.Title>Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Col>
            <Image
              fluid
              alt="#"
              src={`${API_ENDPOINTS.IMAGE}${image?.media}`}
            />
          </Col>
        </Modal.Body>
      </Modal>

      <Modal show={showVoiceRecoderModel} onHide={handleVoiceRecoderModelClose}>
        <Modal.Header closeButton>
          <Modal.Title>Voice Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Col></Col>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
export default BackendLayout(Questions);
