import { createSlice } from "@reduxjs/toolkit";
import { API_ENDPOINTS } from "../client/api.constants";

import { postAPICall, getAPICall, deleteAPICall } from "../client/api.client";

const initialState = {
  sessions: {
    isLoading: false,
  },
  addSession: {
    isLoading: false,
  },
  updateSession: {
    isLoading: false,
  },
  deleteSession: {
    isLoading: false,
  },
  deleteParticipant: {
    isLoading: false,
  },
  participants: {
    isLoading: false,
  },
  availability: {
    isLoading: false,
  },
  updateAvailability: {
    isLoading: false,
  },
};

export const sessionsSlice = createSlice({
  name: "sessions",
  initialState,
  reducers: {
    sessions: (state, action) => {
      state.sessions = action.payload;
    },
    addSession: (state, action) => {
      state.addSession = action.payload;
    },
    updateSession: (state, action) => {
      state.updateSession = action.payload;
    },
    deleteSession: (state, action) => {
      state.deleteSession = action.payload;
    },
    deleteParticipant: (state, action) => {
      state.deleteParticipant = action.payload;
    },
    participants: (state, action) => {
      state.participants = action.payload;
    },
    availability: (state, action) => {
      state.availability = action.payload;
    },
    updateAvailability: (state, action) => {
      state.updateAvailability = action.payload;
    },
    reset: (state, action) => {
      state.sessions = {
        isLoading: false,
      };
      state.addSession = {
        isLoading: false,
      };
      state.updateSession = {
        isLoading: false,
      };
      state.deleteSession = {
        isLoading: false,
      };
      state.deleteParticipant = {
        isLoading: false,
      };
      state.availability = {
        isLoading: false,
      };
      state.updateAvailability = {
        isLoading: false,
      };
    },
  },
});

export const getSessionsAsync = (params) => async (dispatch) => {
  try {
    dispatch(sessions({ isLoading: true }));
    const result = await getAPICall(API_ENDPOINTS.GETSESSIONS, params);
    dispatch(sessions({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(sessions({ isLoading: false }));
  }
};
export const addSessionAsync = (params) => async (dispatch) => {
  try {
    dispatch(addSession({ isLoading: true }));
    const result = await postAPICall(API_ENDPOINTS.ADDSESSION, params);
    dispatch(addSession({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(addSession({ isLoading: false }));
  }
};
export const updateSessionAsync = (params) => async (dispatch) => {
  try {
    dispatch(updateSession({ isLoading: true }));
    const result = await postAPICall(API_ENDPOINTS.UPDATESESSION, params);
    dispatch(updateSession({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(updateSession({ isLoading: false }));
  }
};
export const deleteSessionAsync = (params) => async (dispatch) => {
  try {
    dispatch(deleteSession({ isLoading: true }));
    const result = await deleteAPICall(API_ENDPOINTS.DELETESESSION, params);
    dispatch(deleteSession({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(deleteSession({ isLoading: false }));
  }
};

export const deleteParticipantAsync = (params) => async (dispatch) => {
  try {
    dispatch(deleteParticipant({ isLoading: true }));
    const result = await deleteAPICall(API_ENDPOINTS.DELETEPARTICIPANT, params);
    dispatch(deleteParticipant({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(deleteParticipant({ isLoading: false }));
  }
};
export const getParticipantsAsync = (params) => async (dispatch) => {
  try {
    dispatch(participants({ isLoading: true }));
    const result = await getAPICall(API_ENDPOINTS.PARTICIPANTS, params);
    dispatch(participants({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(participants({ isLoading: false }));
  }
};
export const getAvailabilityAsync = (params) => async (dispatch) => {
  try {
    dispatch(availability({ isLoading: true }));
    const result = await getAPICall(API_ENDPOINTS.GETAVAILABILITY, params);
    dispatch(availability({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(availability({ isLoading: false }));
  }
};
export const updateAvailabilityAsync = (params) => async (dispatch) => {
  try {
    dispatch(updateAvailability({ isLoading: true }));
    const result = await postAPICall(API_ENDPOINTS.UPDATEAVAILABILITY, params);
    dispatch(updateAvailability({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(updateAvailability({ isLoading: false }));
  }
};

export const resetAsync = () => async (dispatch) => {
  dispatch(reset(null));
};

export const {
  updateSession,
  sessions,
  participants,
  addSession,
  deleteSession,
  deleteParticipant,
  availability,
  updateAvailability,
  reset,
} = sessionsSlice.actions;

export const sessionsResponse = (state) => state.sessions.sessions;
export const participantsResponse = (state) => state.sessions.participants;
export const addSessionResponse = (state) => state.sessions.addSession;
export const deleteSessionResponse = (state) => state.sessions.deleteSession;
export const deleteParticipantResponse = (state) => state.sessions.deleteParticipant;
export const updateSessionResponse = (state) => state.sessions.updateSession;
export const availabilityResponse = (state) => state.sessions.availability;
export const updateAvailabilityResponse = (state) =>
  state.sessions.updateAvailability;

export default sessionsSlice.reducer;
