import { createSlice } from "@reduxjs/toolkit";
import { API_ENDPOINTS } from "../client/api.constants";

import { getAPICall, postAPICall } from "../client/api.client";

const initialState = {
  getConversations: {
    isLoading: false,
  },
  getQuestions: {
    isLoading: false,
  },
  giveAnswer: {
    isLoading: false,
  },
};

export const questionsSlice = createSlice({
  name: "questions",
  initialState,
  reducers: {
    getConversations: (state, action) => {
      state.getConversations = action.payload;
    },
    getQuestions: (state, action) => {
      state.getQuestions = action.payload;
    },
    giveAnswer: (state, action) => {
      state.giveAnswer = action.payload;
    },
    reset: (state, action) => {
      state.getConversations = {
        isLoading: false,
      };
      state.giveAnswer = {
        isLoading: false,
      };
      state.getQuestions = {
        isLoading: false,
      };
    },
  },
});

export const getConversationsAsync = (params) => async (dispatch) => {
  try {
    dispatch(getConversations({ isLoading: true }));
    const result = await getAPICall(API_ENDPOINTS.GETCONVERSATIONS, params);
    dispatch(getConversations({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(getConversations({ isLoading: false }));
  }
};

export const getQuestionsAsync = (params) => async (dispatch) => {
  try {
    dispatch(getQuestions({ isLoading: true }));
    const result = await getAPICall(API_ENDPOINTS.GETQUESTIONS, params);
    dispatch(getQuestions({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(getQuestions({ isLoading: false }));
  }
};

export const giveAnswerAsync = (params) => async (dispatch) => {
  try {
    dispatch(giveAnswer({ isLoading: true }));
    const result = await postAPICall(API_ENDPOINTS.GIVEANSWER, params, true);
    dispatch(giveAnswer({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(giveAnswer({ isLoading: false }));
  }
};

export const resetAsync = () => async (dispatch) => {
  dispatch(reset(null));
};

export const { getConversations, getQuestions, reset, giveAnswer } =
  questionsSlice.actions;
export const getConversationsResponse = (state) =>
  state.questions.getConversations;
export const getQuestionsResponse = (state) => state.questions.getQuestions;
export const giveAnswerResponse = (state) => state.questions.giveAnswer;
export default questionsSlice.reducer;
