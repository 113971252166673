import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import AuthLayout from "../../layouts/auth-layout";
import { useSelector, useDispatch } from "react-redux";
import {
  signInAsync,
  signInResponse,
  resetAsync,
} from "../../api/slices/auth.slice";
import { toast } from "react-toastify";
import { useAuth } from "../../utils/auth_context";
import { useNavigate } from "react-router-dom";

function Login(props) {
  const { setLoading } = props;
  const navigate = useNavigate();
  const { login, isAuthenticated } = useAuth();
  const dispatch = useDispatch();
  const data = useSelector(signInResponse);

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/dashboard");
    }
  }, []);

  useEffect(() => {
    setLoading(data.isLoading);
    if (data.response && data.response.status === 200) {
      login(data.response.data);
      dispatch(resetAsync());
      navigate("/dashboard");
    } else {
      toast.error(data.response?.message);
    }
  }, [data]);

  const onLogin = () => {
    if (!email) {
      toast.error("Please enter email");
    } else if (!password) {
      toast.error("Please enter password");
    } else {
      dispatch(
        signInAsync({
          email,
          password,
        })
      );
    }
  };

  return (
    <React.Fragment>
      <Form>
        <Form.Group>
          <label htmlFor="exampleuser1">Email</label>
          <div className="group-icon">
            <input
              id="exampleuser1"
              type="text"
              placeholder="Email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              className="form-control"
            />
            <span className="icon-user text-muted icon-input" />
          </div>
        </Form.Group>
        <Form.Group>
          <label htmlFor="exampleInputPassword1">Password</label>
          <div className="group-icon">
            <input
              id="exampleInputPassword1"
              type="password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              placeholder="Password"
              className="form-control"
            />
            <span className="icon-lock text-muted icon-input" />
          </div>
        </Form.Group>
        <div className="clearfix">
          <div className="float-left">
            <div className="checkbox checkbox-primary margin-r-5">
              <input id="checkbox2" type="checkbox" defaultChecked />
              <label htmlFor="checkbox2"> Remember Me </label>
            </div>
          </div>
          <div className="float-right">
            <input
              type="button"
              onClick={onLogin}
              className="btn btn-block btn-primary btn-rounded box-shadow"
              value="Login"
            />
          </div>
        </div>
      </Form>
    </React.Fragment>
  );
}
export default AuthLayout(Login);
