import React, { useEffect, useState, useRef } from "react";
import JoditEditor from "jodit-react";
import "./index.scss";
import { Row, Col, Card, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import BackendLayout from "../../layouts/backend-layout";

import { useSelector, useDispatch } from "react-redux";
import {
  getPrivacyPolicyAsync,
  privacyPolicyResponse,
  updatePrivacyPolicyAsync,
  updatePrivacyPolicyResponse,
  resetAsync,
} from "../../api/slices/cms.slice";
import { toast } from "react-toastify";

function PrivacyPolicy(props) {
  const { setLoading } = props;
  const dispatch = useDispatch();
  const _privacyPolicyResponse = useSelector(privacyPolicyResponse);
  const _updatePrivacyPolicyResponse = useSelector(updatePrivacyPolicyResponse);

  const editor = useRef(null);
  const [content, setContent] = useState("");
  const config = {
    readonly: false,
  };

  useState(() => {
    dispatch(getPrivacyPolicyAsync({}));
  }, []);

  const onSave = () => {
    if (content) {
      dispatch(updatePrivacyPolicyAsync({ content }));
    }
  };

  useEffect(() => {
    setLoading(_privacyPolicyResponse.isLoading);
    if (
      _privacyPolicyResponse.response &&
      _privacyPolicyResponse.response.status === 200
    ) {
      if (_privacyPolicyResponse.response.data.policies) {
        setContent(_privacyPolicyResponse.response.data.policies.content);
      }
    } else {
      toast.error(_privacyPolicyResponse.response?.message);
    }
  }, [_privacyPolicyResponse]);

  useEffect(() => {
    setLoading(_updatePrivacyPolicyResponse.isLoading);
    if (
      _updatePrivacyPolicyResponse.response &&
      _updatePrivacyPolicyResponse.response.status === 200
    ) {
      dispatch(getPrivacyPolicyAsync({}));
    } else {
      toast.error(_updatePrivacyPolicyResponse.response?.message);
    }
  }, [_updatePrivacyPolicyResponse]);

  return (
    <React.Fragment>
      <Row>
        <Col>
          <Card>
            <Card.Header>Privacy Policy</Card.Header>
            <Card.Body>
              <form method="get" action className="form-horizontal">
                <Form.Group>
                  <JoditEditor
                    ref={editor}
                    value={content}
                    config={config}
                    tabIndex={1}
                    onBlur={(newContent) => setContent(newContent)}
                    //onChange={(newContent) => setContent(newContent)}
                  />
                </Form.Group>

                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    onSave();
                  }}
                  className="btn btn-success btn-icon"
                >
                  <i className="fa fa-floppy-o" />
                  Save
                </Link>
              </form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default BackendLayout(PrivacyPolicy);
