import { createSlice } from "@reduxjs/toolkit";
import { API_ENDPOINTS } from "../client/api.constants";

import { postAPICall, getAPICall, deleteAPICall } from "../client/api.client";

const initialState = {
  faq_categories: {
    isLoading: false,
  },
  faq_category_update: {
    isLoading: false,
  },
  faq_category_delete: {
    isLoading: false,
  },
  faq_category_add: {
    isLoading: false,
  },

  faqs: {
    isLoading: false,
  },
  faq_update: {
    isLoading: false,
  },
  faq_delete: {
    isLoading: false,
  },
  faq_add: {
    isLoading: false,
  },

  get_privacy_policy: {
    isLoading: false,
  },
  update_privacy_policy: {
    isLoading: false,
  },

  get_terms_conditions: {
    isLoading: false,
  },
  update_terms_conditions: {
    isLoading: false,
  },

  blogs: {
    isLoading: false,
  },
  blog_update: {
    isLoading: false,
  },
  blog_delete: {
    isLoading: false,
  },
  blog_add: {
    isLoading: false,
  },
};

export const cmsSlice = createSlice({
  name: "cms",
  initialState,
  reducers: {
    faq_categories: (state, action) => {
      state.faq_categories = action.payload;
    },
    faq_category_update: (state, action) => {
      state.faq_category_update = action.payload;
    },
    faq_category_add: (state, action) => {
      state.faq_category_add = action.payload;
    },
    faq_category_delete: (state, action) => {
      state.faq_category_delete = action.payload;
    },

    faqs: (state, action) => {
      state.faqs = action.payload;
    },
    faq_update: (state, action) => {
      state.faq_update = action.payload;
    },
    faq_add: (state, action) => {
      state.faq_add = action.payload;
    },
    faq_delete: (state, action) => {
      state.faq_delete = action.payload;
    },

    get_privacy_policy: (state, action) => {
      state.get_privacy_policy = action.payload;
    },
    update_privacy_policy: (state, action) => {
      state.update_privacy_policy = action.payload;
    },

    get_terms_conditions: (state, action) => {
      state.get_terms_conditions = action.payload;
    },
    update_terms_conditions: (state, action) => {
      state.update_terms_conditions = action.payload;
    },

    blogs: (state, action) => {
      state.blogs = action.payload;
    },
    blog_update: (state, action) => {
      state.blog_update = action.payload;
    },
    blog_delete: (state, action) => {
      state.blog_delete = action.payload;
    },
    blog_add: (state, action) => {
      state.blog_add = action.payload;
    },

    reset: (state, action) => {
      state.faq_categories = {
        isLoading: false,
      };
      state.faq_category_update = {
        isLoading: false,
      };
      state.faq_category_add = {
        isLoading: false,
      };
      state.faq_category_delete = {
        isLoading: false,
      };

      state.faqs = {
        isLoading: false,
      };
      state.faq_update = {
        isLoading: false,
      };
      state.faq_add = {
        isLoading: false,
      };
      state.faq_delete = {
        isLoading: false,
      };

      state.get_privacy_policy = {
        isLoading: false,
      };
      state.update_privacy_policy = {
        isLoading: false,
      };

      state.get_terms_conditions = {
        isLoading: false,
      };
      state.update_terms_conditions = {
        isLoading: false,
      };

      state.blogs = {
        isLoading: false,
      };
      state.blog_update = {
        isLoading: false,
      };
      state.blog_delete = {
        isLoading: false,
      };
      state.blog_add = {
        isLoading: false,
      };
    },
  },
});

export const getFaqCategoriesAsync = (params) => async (dispatch) => {
  try {
    dispatch(faq_categories({ isLoading: true }));
    const result = await getAPICall(API_ENDPOINTS.GETFAQCATEGORIES, params);
    dispatch(faq_categories({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(faq_categories({ isLoading: false }));
  }
};

export const updateFAQCategoryAsync = (params) => async (dispatch) => {
  try {
    dispatch(faq_category_update({ isLoading: true }));
    const result = await postAPICall(API_ENDPOINTS.UPDATEFAQCATEGORIES, params);
    dispatch(faq_category_update({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(faq_category_update({ isLoading: false }));
  }
};

export const addFAQCategoryAsync = (params) => async (dispatch) => {
  try {
    dispatch(faq_category_add({ isLoading: true }));
    const result = await postAPICall(API_ENDPOINTS.ADDFAQCATEGORIES, params);
    dispatch(faq_category_add({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(faq_category_add({ isLoading: false }));
  }
};

export const deleteFAQCategoryAsync = (params) => async (dispatch) => {
  try {
    dispatch(faq_category_delete({ isLoading: true }));
    const result = await deleteAPICall(
      API_ENDPOINTS.DELETEFAQCATEGORIES,
      params
    );
    dispatch(faq_category_delete({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(faq_category_delete({ isLoading: false }));
  }
};

export const getFaqAsync = (params) => async (dispatch) => {
  try {
    dispatch(faqs({ isLoading: true }));
    const result = await getAPICall(API_ENDPOINTS.GETFAQS, params);
    dispatch(faqs({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(faqs({ isLoading: false }));
  }
};

export const updateFAQAsync = (params) => async (dispatch) => {
  try {
    dispatch(faq_update({ isLoading: true }));
    const result = await postAPICall(API_ENDPOINTS.UPDATEFAQ, params);
    dispatch(faq_update({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(faq_update({ isLoading: false }));
  }
};

export const addFAQAsync = (params) => async (dispatch) => {
  try {
    dispatch(faq_add({ isLoading: true }));
    const result = await postAPICall(API_ENDPOINTS.ADDFAQ, params);
    dispatch(faq_add({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(faq_add({ isLoading: false }));
  }
};

export const deleteFAQAsync = (params) => async (dispatch) => {
  try {
    dispatch(faq_delete({ isLoading: true }));
    const result = await deleteAPICall(API_ENDPOINTS.DELETEFAQ, params);
    dispatch(faq_delete({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(faq_delete({ isLoading: false }));
  }
};

export const resetAsync = () => async (dispatch) => {
  dispatch(reset(null));
};

export const getPrivacyPolicyAsync = (params) => async (dispatch) => {
  try {
    dispatch(get_privacy_policy({ isLoading: true }));
    const result = await getAPICall(API_ENDPOINTS.GETPRIVACYPOLICY, params);
    dispatch(get_privacy_policy({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(get_privacy_policy({ isLoading: false }));
  }
};

export const updatePrivacyPolicyAsync = (params) => async (dispatch) => {
  try {
    dispatch(update_privacy_policy({ isLoading: true }));
    const result = await postAPICall(API_ENDPOINTS.UPDATEPRIVACYPOLICY, params);
    dispatch(
      update_privacy_policy({ isLoading: false, response: result.data })
    );
  } catch (error) {
    dispatch(update_privacy_policy({ isLoading: false }));
  }
};

export const getTermsConditionsAsync = (params) => async (dispatch) => {
  try {
    dispatch(get_terms_conditions({ isLoading: true }));
    const result = await getAPICall(API_ENDPOINTS.GETTERMCONDITIONS, params);
    dispatch(get_terms_conditions({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(get_terms_conditions({ isLoading: false }));
  }
};

export const updateTermsConditionsAsync = (params) => async (dispatch) => {
  try {
    dispatch(update_terms_conditions({ isLoading: true }));
    const result = await postAPICall(
      API_ENDPOINTS.UPDATETERMCONDITIONS,
      params
    );
    dispatch(
      update_terms_conditions({ isLoading: false, response: result.data })
    );
  } catch (error) {
    dispatch(update_terms_conditions({ isLoading: false }));
  }
};

export const getBlogsAsync = (params) => async (dispatch) => {
  try {
    dispatch(blogs({ isLoading: true }));
    const result = await getAPICall(API_ENDPOINTS.GETBLOGS, params);
    dispatch(blogs({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(blogs({ isLoading: false }));
  }
};

export const updateBlogAsync = (params) => async (dispatch) => {
  try {
    dispatch(blog_update({ isLoading: true }));
    const result = await postAPICall(API_ENDPOINTS.UPDATEBLOG, params, true);
    dispatch(blog_update({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(blog_update({ isLoading: false }));
  }
};

export const addBlogAsync = (params) => async (dispatch) => {
  try {
    dispatch(blog_add({ isLoading: true }));
    const result = await postAPICall(API_ENDPOINTS.ADDBLOG, params, true);
    dispatch(blog_add({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(blog_add({ isLoading: false }));
  }
};

export const deleteBlogsAync = (params) => async (dispatch) => {
  try {
    dispatch(blog_delete({ isLoading: true }));
    const result = await deleteAPICall(API_ENDPOINTS.DELETEBLOG, params);
    dispatch(blog_delete({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(blog_delete({ isLoading: false }));
  }
};

export const {
  faq_categories,
  faq_category_update,
  faq_category_delete,
  faq_category_add,

  faqs,
  faq_update,
  faq_delete,
  faq_add,

  blogs,
  blog_update,
  blog_delete,
  blog_add,

  get_privacy_policy,
  update_privacy_policy,

  get_terms_conditions,
  update_terms_conditions,
  reset,
} = cmsSlice.actions;

export const faqCategoriesResponse = (state) => state.cms.faq_categories;
export const faqUpdateCategoryResponse = (state) =>
  state.cms.faq_category_update;
export const faqDeleteCategoryResponse = (state) =>
  state.cms.faq_category_delete;
export const faqAddCategoryResponse = (state) => state.cms.faq_category_add;

export const faqResponse = (state) => state.cms.faqs;
export const faqUpdateResponse = (state) => state.cms.faq_update;
export const faqDeleteResponse = (state) => state.cms.faq_delete;
export const faqAddResponse = (state) => state.cms.faq_add;

export const blogResponse = (state) => state.cms.blogs;
export const blogUpdateResponse = (state) => state.cms.blog_update;
export const blogDeleteResponse = (state) => state.cms.blog_delete;
export const blogAddResponse = (state) => state.cms.blog_add;

export const privacyPolicyResponse = (state) => state.cms.get_privacy_policy;
export const updatePrivacyPolicyResponse = (state) =>
  state.cms.update_privacy_policy;

export const termsConditionsResponse = (state) =>
  state.cms.get_terms_conditions;
export const updatetermsConditionsResponse = (state) =>
  state.cms.update_terms_conditions;

export default cmsSlice.reducer;
