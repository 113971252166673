import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Row, Container, Col, Image } from "react-bootstrap";
import Loader from "../../components/loader";

export const AuthLayout = (WrappedComponent) => {
  function HOC(props) {
    const [isLoading, setLoading] = useState(false);

    const setLoadingState = (isComponentLoading) => {
      setLoading(isComponentLoading);
    };

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    return (
      <React.Fragment>
        <Helmet>
          <title> TCDL </title>
        </Helmet>

        <div className="misc-wrapper">
          <div className="misc-content">
            <Container>
              <Row className="justify-content-center">
                <Col sm="12" md="5" lg="4" className="col-4">
                  <div className="misc-header text-center">
                    <Image
                      alt=""
                      src="/assets/img/logo-dark.png"
                      className="toggle-none hidden-xs"
                      width="100"
                    />
                  </div>
                  <div className="misc-box">
                    <WrappedComponent {...props} setLoading={setLoadingState} />
                  </div>
                  <div className="text-center misc-footer">
                    <p>Copyright © 2023 TCDL</p>
                  </div>
                </Col>
              </Row>

              <Loader show={isLoading} />
            </Container>
          </div>
        </div>
      </React.Fragment>
    );
  }

  return HOC;
};
export default AuthLayout;
