import React, { useEffect, useState } from "react";
import "./index.scss";
import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Pagination,
  Modal,
  InputGroup,
  Form,
  Image,
} from "react-bootstrap";
import BackendLayout from "../../layouts/backend-layout";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import {
  getFaqCategoriesAsync,
  faqCategoriesResponse,
  updateFAQCategoryAsync,
  faqUpdateCategoryResponse,
  deleteFAQCategoryAsync,
  faqDeleteCategoryResponse,
  addFAQCategoryAsync,
  faqAddCategoryResponse,
  resetAsync,
} from "../../api/slices/cms.slice";
import { toast } from "react-toastify";

function FAQCategories(props) {
  const { setLoading } = props;
  const dispatch = useDispatch();
  const _faqCategoriesResponse = useSelector(faqCategoriesResponse);
  const _faqUpdateCategoryResponse = useSelector(faqUpdateCategoryResponse);
  const _faqDeleteCategoryResponse = useSelector(faqDeleteCategoryResponse);
  const _faqAddCategoryResponse = useSelector(faqAddCategoryResponse);

  const [index, setIndex] = useState();
  const [page, setPage] = useState({
    page: 1,
    page_size: 15,
  });
  const [category, setCategory] = useState("");
  const [order_by, setOrderBy] = useState("DESC");
  const [query, setQuery] = useState();
  const [categories, setCategories] = useState([]);
  const [paging, setPaging] = useState([]);

  const [showAddModel, setShowAddModel] = useState(false);
  const handleAddModelClose = () => {
    setShowAddModel(false);
  };
  const handleAddModelShow = () => {
    setCategory("");
    setShowAddModel(true);
  };

  const [showEditModel, setShowEditModel] = useState(false);
  const handleEditModelClose = () => {
    setShowEditModel(false);
  };
  const handleEditModelShow = (index) => {
    setIndex(index);
    setCategory(categories[index].name);
    setShowEditModel(true);
  };

  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const handleDeleteModelClose = () => {
    setShowDeleteModel(false);
  };
  const handleDeleteModelShow = (index) => {
    setIndex(index);
    setShowDeleteModel(true);
  };

  const columns = [
    { key: "id", value: "ID" },
    { key: "name", value: "Name" },
    { key: "created_at", value: "Created At" },
    { key: "updated_at", value: "Last Updated At" },
    { key: "active", value: "Active" },
    { key: "action", value: "Action" },
  ];

  useEffect(() => {
    dispatch(
      getFaqCategoriesAsync({ page: page.page, page_size: page.page_size })
    );
  }, [page]);

  useEffect(() => {
    setLoading(_faqCategoriesResponse.isLoading);
    if (
      _faqCategoriesResponse.response &&
      _faqCategoriesResponse.response.status === 200
    ) {
      window.scrollTo(0, 0);
      setCategories(_faqCategoriesResponse.response.data.categories);
      setPaging(_faqCategoriesResponse.response.data.paging);
    } else {
      toast.error(_faqCategoriesResponse.response?.message);
    }
  }, [_faqCategoriesResponse]);

  useEffect(() => {
    setLoading(_faqUpdateCategoryResponse.isLoading);
    if (
      _faqUpdateCategoryResponse.response &&
      _faqUpdateCategoryResponse.response.status === 200
    ) {
      setCategories((categories) => {
        const updatedData = [...categories];
        updatedData[index] = {
          ...updatedData[index],
          ..._faqUpdateCategoryResponse.response.data,
        };
        return updatedData;
      });
    } else {
      toast.error(_faqUpdateCategoryResponse.response?.message);
    }
  }, [_faqUpdateCategoryResponse]);

  useEffect(() => {
    setLoading(_faqAddCategoryResponse.isLoading);
    if (
      _faqAddCategoryResponse.response &&
      _faqAddCategoryResponse.response.status === 200
    ) {
      setCategory("");
      dispatch(
        getFaqCategoriesAsync({ page: page.page, page_size: page.page_size })
      );
    } else {
      toast.error(_faqAddCategoryResponse.response?.message);
    }
  }, [_faqAddCategoryResponse]);

  useEffect(() => {
    setLoading(_faqDeleteCategoryResponse.isLoading);
    if (
      _faqDeleteCategoryResponse.response &&
      _faqDeleteCategoryResponse.response.status === 200
    ) {
      setCategories((categories) => {
        const updatedData = [...categories];
        updatedData.splice(index, 1);
        return updatedData;
      });
    } else {
      toast.error(_faqDeleteCategoryResponse.response?.message);
    }
  }, [_faqDeleteCategoryResponse]);

  const onPageChange = (i) => {
    setPage({ ...page, page: i });
  };

  const onOrderClick = (key) => {
    const ignoreKey = ["action", "updated_at"];
    if (ignoreKey.includes(key)) {
      return;
    }
    const order = order_by == "DESC" ? "ASC" : "DESC";
    setOrderBy(order);
    dispatch(
      getFaqCategoriesAsync({
        page: page.page,
        page_size: page.page_size,
        order_by: key,
        order,
      })
    );
  };

  const onQueryChange = (e) => {
    setQuery(e.target.value);
    if (e.target.value == "") {
      dispatch(
        getFaqCategoriesAsync({
          page: page.page,
          page_size: page.page_size,
        })
      );
    }
  };

  const onSearch = () => {
    dispatch(
      getFaqCategoriesAsync({
        page: page.page,
        page_size: page.page_size,
        search: query,
      })
    );
  };

  const onDelete = () => {
    setShowDeleteModel(false);
    const data = {
      id: categories[index].id,
    };
    dispatch(deleteFAQCategoryAsync(data));
  };

  const onAddCategory = () => {
    setShowAddModel(false);
    const data = {
      name: category,
    };
    dispatch(addFAQCategoryAsync(data));
  };

  const onEditCategory = () => {
    setShowEditModel(false);
    const data = {
      id: categories[index].id,
      name: category,
    };
    dispatch(updateFAQCategoryAsync(data));
  };

  const handleActiveChange = (index) => {
    setIndex(index);
    dispatch(
      updateFAQCategoryAsync({
        id: categories[index].id,
        active: !categories[index].active,
      })
    );
  };

  return (
    <React.Fragment>
      <Row>
        <Col md="12">
          <Card>
            <Card.Header>
              FAQ Categories
              <p className="text-muted">All FAQ Categories</p>
            </Card.Header>
            <Card.Body>
              <Row className="ml-1">
                <Col>
                  <span
                    onClick={handleAddModelShow}
                    to="#ecommerce-add"
                    className="btn btn-primary btn-rounded box-shadow btn-icon"
                  >
                    <i className="fa fa-plus" /> Add Category
                  </span>
                </Col>
                <Col md="auto">
                  <div className="mb-2">
                    <InputGroup>
                      <Form.Control
                        value={query}
                        onChange={onQueryChange}
                        type="text"
                        placeholder="Search"
                      />
                      <div className="input-group-btn">
                        <Button variant="primary" onClick={onSearch}>
                          <i className="fa fa-search" />
                        </Button>
                      </div>
                    </InputGroup>
                  </div>
                </Col>
              </Row>

              <div className="table-responsive">
                <Table
                  id="datatable1"
                  striped="columns"
                  className="table table-striped table-hover mt-4"
                >
                  <thead>
                    <tr>
                      {columns.map((item) => {
                        return (
                          <th
                            role="button"
                            className="text-center"
                            onClick={() => onOrderClick(item.key)}
                          >
                            <Row className="justify-content-md-center ml-2 mr-2">
                              <strong>{item.value}</strong>
                            </Row>
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {categories.map((item, i) => {
                      return (
                        <tr className="text-center">
                          <td>{item.id}</td>
                          <td>{item.name}</td>
                          <td>{item.created_at}</td>
                          <td>{item.updated_at}</td>
                          <td>
                            <div className="custom-control custom-switch">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id={`switch_${i}`}
                                checked={item.active}
                                readOnly
                                onChange={() => {
                                  handleActiveChange(i);
                                }}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={`switch_${i}`}
                              />
                            </div>
                          </td>
                          <td nowrap="nowrap" className="text-center">
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditModelShow(i)}
                            >
                              <i className="fa fa-edit" />
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteModelShow(i)}
                            >
                              <i className="fa fa-trash" />
                            </Button>{" "}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
              <Pagination>
                {Array.from({ length: paging.total_pages }).map((item, i) => {
                  return (
                    <Pagination.Item
                      onClick={() => onPageChange(i + 1)}
                      key={i}
                      active={i + 1 === paging.currentPage}
                    >
                      {i + 1}
                    </Pagination.Item>
                  );
                })}
              </Pagination>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal show={showEditModel} onHide={handleEditModelClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group>
                <Form.Control
                  value={category}
                  onChange={(e) => {
                    setCategory(e.target.value);
                  }}
                  type="text"
                  placeholder="Name"
                />
              </Form.Group>
            </Col>
          </Row>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEditModelClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              if (category == "") {
                toast.error("Please enter category name");
              } else {
                onEditCategory();
              }
            }}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showAddModel} onHide={handleAddModelClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group>
                <Form.Control
                  value={category}
                  onChange={(e) => {
                    setCategory(e.target.value);
                  }}
                  type="text"
                  placeholder="Name"
                />
              </Form.Group>
            </Col>
          </Row>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleAddModelClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              if (category == "") {
                toast.error("Please enter category name");
              } else {
                onAddCategory();
              }
            }}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeleteModel} onHide={handleDeleteModelClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            All FAQ's in this category will be deteled. Are you sure you want to
            delete.?
          </p>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteModelClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={(e) => {
              onDelete();
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
export default BackendLayout(FAQCategories);
