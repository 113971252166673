// apiConstants.ts
const BASE_URL = "https://api.thecardsdontlie.com/v1"; // Replace with your base URL
const IMAGE_BASE_URL = "https://api.thecardsdontlie.com/"; // Replace with your base URL

export const API_ENDPOINTS = {
  IMAGE: `${IMAGE_BASE_URL}uploads/`,
  APIKEY: "V9dlnpPotY4NzJWB9cwhdLeAba1Zc4UyFlmwq9df2PrH0KquXBu9e7hJuAa5jxPR",
  SIGNIN: `${BASE_URL}/auth/admin`,
  USERS: `${BASE_URL}/admin/users/get-users`,
  EXPORTUSERS: `${BASE_URL}/admin/users/export-users`,
  UPDATEUSER: `${BASE_URL}/admin/users/update-user`,

  SUBSCRIPTIONREQUESTS: `${BASE_URL}/admin/subscriptions/get-subscription-requests`,
  UPDATESUBSCRIPTIONREQUEST: `${BASE_URL}/admin/subscriptions/update-subscription-requests`,

  GETPLANS: `${BASE_URL}/admin/subscriptions/get-subscription-plans`,
  ADDPLANS: `${BASE_URL}/admin/subscriptions/add-subscription-plan`,
  UPDATEPLAN: `${BASE_URL}/admin/subscriptions/update-subscription-plan`,
  DELETEPLAN: `${BASE_URL}/admin/subscriptions/delete-subscription-plan`,

  GETFAQCATEGORIES: `${BASE_URL}/admin/cms/get-faq-categories`,
  UPDATEFAQCATEGORIES: `${BASE_URL}/admin/cms/update-faq-category`,
  DELETEFAQCATEGORIES: `${BASE_URL}/admin/cms/delete-faq-category`,
  ADDFAQCATEGORIES: `${BASE_URL}/admin/cms/add-faq-category`,

  GETFAQS: `${BASE_URL}/admin/cms/get-faqs`,
  UPDATEFAQ: `${BASE_URL}/admin/cms/update-faq`,
  DELETEFAQ: `${BASE_URL}/admin/cms/delete-faq`,
  ADDFAQ: `${BASE_URL}/admin/cms/add-faq`,

  GETBLOGS: `${BASE_URL}/admin/cms/get-blogs`,
  UPDATEBLOG: `${BASE_URL}/admin/cms/update-blog`,
  DELETEBLOG: `${BASE_URL}/admin/cms/delete-blog`,
  ADDBLOG: `${BASE_URL}/admin/cms/add-blog`,

  GETSESSIONS: `${BASE_URL}/admin/sessions/get-sessions`,
  ADDSESSION: `${BASE_URL}/admin/sessions/add-session`,
  UPDATESESSION: `${BASE_URL}/admin/sessions/update-session`,
  DELETESESSION: `${BASE_URL}/admin/sessions/delete-session`,
  DELETEPARTICIPANT: `${BASE_URL}/admin/sessions/delete-participant`,
  PARTICIPANTS: `${BASE_URL}/admin/sessions/get-participants`,
  GETCONVERSATIONS: `${BASE_URL}/admin/questions/get-conversations`,
  GETQUESTIONS: `${BASE_URL}/admin/questions/get-questions`,
  GIVEANSWER: `${BASE_URL}/admin/questions/give-answer`,

  GETAVAILABILITY: `${BASE_URL}/admin/sessions/get-session-availability`,
  UPDATEAVAILABILITY: `${BASE_URL}/admin/sessions/update-session-availability`,

  GETPRIVACYPOLICY: `${BASE_URL}/admin/cms/get-privacy-policy`,
  UPDATEPRIVACYPOLICY: `${BASE_URL}/admin/cms/update-privacy-policy`,

  GETTERMCONDITIONS: `${BASE_URL}/admin/cms/get-terms-conditions`,
  UPDATETERMCONDITIONS: `${BASE_URL}/admin/cms/update-terms-conditions`,


};
