import { createSlice } from "@reduxjs/toolkit";
import { API_ENDPOINTS } from "../client/api.constants";

import { postAPICall } from "../client/api.client";

const initialState = {
  signIn: {
    isLoading: false,
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    signIn: (state, action) => {
      state.signIn = action.payload;
    },
    reset: (state, action) => {
      state.signIn = {
        isLoading: false,
      };
    },
  },
});

export const signInAsync = (params) => async (dispatch) => {
  try {
    dispatch(signIn({ isLoading: true }));
    const result = await postAPICall(API_ENDPOINTS.SIGNIN, params);
    dispatch(signIn({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(signIn({ isLoading: false }));
  }
};

export const resetAsync = () => async (dispatch) => {
  dispatch(reset(null));
};

export const { signIn, reset } = authSlice.actions;
export const signInResponse = (state) => state.auth.signIn;
export default authSlice.reducer;
