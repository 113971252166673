import React from "react";
import { Spinner, Modal } from "react-bootstrap";

const Loader = ({ show }) => {
  return (
    <Modal
      show={show}
      backdrop="static"
      keyboard={false}
      centered
      dialogClassName="transparent-modal"
    >
      <Modal.Body className="text-center">
        <Spinner animation="border" role="status" variant="primary"></Spinner>
      </Modal.Body>
    </Modal>
  );
};

export default Loader;
