import React, { useEffect, useState } from "react";
import "./index.scss";
import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Pagination,
  Modal,
  InputGroup,
  Form,
  Image,
  Tabs,
  Tab,
} from "react-bootstrap";
import BackendLayout from "../../layouts/backend-layout";

import { useSelector, useDispatch } from "react-redux";
import {
  getSessionsAsync,
  sessionsResponse,
  updateSessionAsync,
  updateSessionResponse,
  addSessionAsync,
  addSessionResponse,
  deleteSessionAsync,
  deleteSessionResponse,
  deleteParticipantAsync,
  deleteParticipantResponse,
  getParticipantsAsync,
  participantsResponse,
  getAvailabilityAsync,
  availabilityResponse,
  updateAvailabilityAsync,
  updateAvailabilityResponse,
  resetAsync,
} from "../../api/slices/sessions.slice";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./customDatePickerWidth.css";
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";

function Sessions(props) {
  const { setLoading } = props;
  const dispatch = useDispatch();
  const _sessionsResponse = useSelector(sessionsResponse);
  const _addSessionResponse = useSelector(addSessionResponse);
  const _updateSessionResponse = useSelector(updateSessionResponse);
  const _deleteSessionResponse = useSelector(deleteSessionResponse);
  const _deleteParticipantResponse = useSelector(deleteParticipantResponse);
  const _participantsResponse = useSelector(participantsResponse);
  const _availabilityResponse = useSelector(availabilityResponse);
  const _updateAvailabilityResponse = useSelector(updateAvailabilityResponse);

  const [page, setPage] = useState({
    page: 1,
    page_size: 15,
  });
  const [sessions, setSessions] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [events, setEvents] = useState([]);
  const [participant, setParticipant] = useState();
  const [isCalanderView, setCalanderView] = useState(true);
  const [filter, setFilter] = useState("GROUP");
  const [order_by, setOrderBy] = useState("DESC");
  const [paging, setPaging] = useState([]);
  const [participantsColumns, setParticipantColumns] = useState([
    { key: "id", value: "ID" },
    { key: "first_name", value: "First Name" },
    { key: "last_name", value: "Last Name" },
    { key: "email", value: "Email" },
    { key: "created_at", value: "Enroll at" },
    { key: "action", value: "Action" },
  ]);
  const [columns, setColumns] = useState([
    { key: "id", value: "ID" },
    { key: "seats", value: "Seats" },
    { key: "date", value: "Date" },
    { key: "total_participants", value: "Participants" },
    { key: "created_at", value: "Created At" },
    { key: "action", value: "Action" },
  ]);
  const [timing, setTiming] = useState({
    monday_start_time: "09:00",
    monday_end_time: "18:00",
    is_monday_holiday: true,
    tuesday_start_time: "09:00",
    tuesday_end_time: "18:00",
    is_tuesday_holiday: true,
    wednesday_start_time: "09:00",
    wednesday_end_time: "18:00",
    is_wednesday_holiday: true,
    thursday_start_time: "09:00",
    thursday_end_time: "18:00",
    is_thursday_holiday: true,
    friday_start_time: "09:00",
    friday_end_time: "18:00",
    is_friday_holiday: true,
    saturday_start_time: "09:00",
    saturday_end_time: "18:00",
    is_saturday_holiday: true,
    sunday_start_time: "09:00",
    sunday_end_time: "18:00",
    is_sunday_holiday: true,
  });
  const localizer = momentLocalizer(moment);

  const [session, setSession] = useState({ date: new Date() });

  const [showAddModel, setShowAddModel] = React.useState(false);
  const handleAddModelClose = () => setShowAddModel(false);
  const handleAddModelShow = () => {
    setShowAddModel(true);
  };

  const handleViewSwitch = () => {
    const x = !isCalanderView;
    setCalanderView(x);
    setFilter("GROUP");
    setParticipantColumns([
      { key: "id", value: "ID" },
      { key: "first_name", value: "First Name" },
      { key: "last_name", value: "Last Name" },
      { key: "email", value: "Email" },
      { key: "created_at", value: "Enroll at" },
      { key: "action", value: "Action" },
    ]);
    setColumns([
      { key: "id", value: "ID" },
      { key: "seats", value: "Seats" },
      { key: "date", value: "Date" },
      { key: "total_participants", value: "Participants" },
      { key: "created_at", value: "Created At" },
      { key: "action", value: "Action" },
    ]);
    setPage({
      page: 1,
      page_size: 15,
    });
    if (x) {
      dispatch(
        getSessionsAsync({
          month: moment().format("YYYY-MM-DD"),
          filter: "GROUP",
        })
      );
    } else {
      dispatch(
        getSessionsAsync({
          page: page.page,
          page_size: page.page_size,
          filter: "GROUP",
        })
      );
    }

    if (!isCalanderView) {
    }
  };

  const [showUpdateModel, setShowUpdateModel] = React.useState(false);
  const handleUpdateModelClose = () => setShowUpdateModel(false);
  const handleUpdateModelShow = (item) => {
    setSession(item);
    setShowUpdateModel(true);
  };

  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const handleDeleteModelClose = () => {
    setShowDeleteModel(false);
  };
  const handleDeleteModelShow = (item) => {
    setSession(item);
    setShowDeleteModel(true);
  };

  const [showStartModel, setShowStartModel] = useState(false);
  const handleStartModelClose = () => {
    setShowStartModel(false);
  };
  const handleStartModelShow = (item) => {
    setSession(item);
    setShowStartModel(true);
  };

  const [showParticipantsModel, setShowParticipantsModel] = useState(false);
  const handleParticipantsModelClose = () => {
    setShowParticipantsModel(false);
  };
  const handleParticipantsModelShow = (item) => {
    setSession(item);
    dispatch(getParticipantsAsync({ id: item.id }));
  };

  const [showTimingModel, setShowTimingModel] = useState(false);
  const handleTimingModelClose = () => {
    setShowTimingModel(false);
  };
  const handleTimingModelShow = (item) => {
    setShowTimingModel(true);
  };

  const [showDeleteParticipantModel, setShowDeleteParticipantModel] =
    useState(false);
  const handleDeleteParticipantModelClose = () => {
    setShowDeleteParticipantModel(false);
  };
  const handleDeleteParticipantModelShow = (item) => {
    setParticipant(item);
    setShowDeleteParticipantModel(true);
  };

  const [showEventModel, setShowEventModel] = useState(false);
  const handleEventModelClose = () => {
    setShowEventModel(false);
  };
  const handleEventModelShow = (item) => {
    setSession(item);
    dispatch(getParticipantsAsync({ id: item.id }));
  };

  useEffect(() => {
    const params =
      filter == "HISTORY"
        ? {
            page: page.page,
            page_size: page.page_size,
            filter: "GROUP",
            past_sessions: true,
          }
        : { page: page.page, page_size: page.page_size, filter };
    if (isCalanderView) {
      dispatch(
        getSessionsAsync({
          month: moment().format("YYYY-MM-DD"),
          filter: "GROUP",
        })
      );
    } else {
      dispatch(getSessionsAsync(params));
    }

    dispatch(getAvailabilityAsync({}));
  }, [page]);

  useEffect(() => {
    setLoading(_sessionsResponse.isLoading);
    if (
      _sessionsResponse.response &&
      _sessionsResponse.response.status === 200
    ) {
      window.scrollTo(0, 0);
      if (isCalanderView) {
        const events = _sessionsResponse.response.data.sessions.map((item) => {
          const d = moment(item.date, "YYYY-MM-DD hh:mm a");
          return {
            ...item,
            type: "GROUP",
            hexColor: item.session_type == "GROUP" ? "005DB0" : "01AEB0",
            title: `${d.format("hh:mm A")} ${
              item.session_type == "GROUP" ? "Group" : "OneToOne"
            }`,
            start: d.format("YYYY-MM-DD"),
            end: d.format("YYYY-MM-DD"),
          };
        });
        setEvents(events);
      } else {
        setSessions(_sessionsResponse.response.data.sessions);
        setPaging(_sessionsResponse.response.data.paging);
      }
    } else {
      toast.error(_sessionsResponse.response?.message);
    }
  }, [_sessionsResponse]);

  useEffect(() => {
    setLoading(_updateSessionResponse.isLoading);
    if (
      _updateSessionResponse.response &&
      _updateSessionResponse.response.status === 200
    ) {
      toast.success(_updateSessionResponse.response?.message);
      setPage({ page: 1, page_size: 15 });
    } else {
      toast.error(_updateSessionResponse.response?.message);
    }
  }, [_updateSessionResponse]);

  useEffect(() => {
    setLoading(_addSessionResponse.isLoading);
    if (
      _addSessionResponse.response &&
      _addSessionResponse.response.status === 200
    ) {
      toast.success(_addSessionResponse.response?.message);
      setPage({ page: 1, page_size: 15 });
    } else {
      toast.error(_addSessionResponse.response?.message);
    }
  }, [_addSessionResponse]);

  useEffect(() => {
    setLoading(_deleteSessionResponse.isLoading);
    if (
      _deleteSessionResponse.response &&
      _deleteSessionResponse.response.status === 200
    ) {
      toast.success(_deleteSessionResponse.response?.message);
      setPage({ page: 1, page_size: 15 });
    } else {
      toast.error(_deleteSessionResponse.response?.message);
    }
  }, [_deleteSessionResponse]);

  useEffect(() => {
    setLoading(_deleteParticipantResponse.isLoading);
    if (
      _deleteParticipantResponse.response &&
      _deleteParticipantResponse.response.status === 200
    ) {
      toast.success(_deleteParticipantResponse.response?.message);
      setShowParticipantsModel(false);
      setPage({ page: 1, page_size: 15 });
    } else {
      toast.error(_deleteSessionResponse.response?.message);
    }
  }, [_deleteParticipantResponse]);

  useEffect(() => {
    setLoading(_participantsResponse.isLoading);
    if (
      _participantsResponse.response &&
      _participantsResponse.response.status === 200
    ) {
      if (isCalanderView) {
        setShowEventModel(true);
      } else {
        setShowParticipantsModel(true);
      }
      setParticipants(_participantsResponse.response.data.participants);
    } else {
      toast.error(_participantsResponse.response?.message);
    }
  }, [_participantsResponse]);

  useEffect(() => {
    setLoading(_availabilityResponse.isLoading);
    if (
      _availabilityResponse.response &&
      _availabilityResponse.response.status === 200
    ) {
      setTiming(_availabilityResponse.response.data.availability);
    } else {
      toast.error(_availabilityResponse.response?.message);
    }
  }, [_availabilityResponse]);

  useEffect(() => {
    setLoading(_updateAvailabilityResponse.isLoading);
    if (
      _updateAvailabilityResponse.response &&
      _updateAvailabilityResponse.response.status === 200
    ) {
      setTiming(_updateAvailabilityResponse.response.data.availability);
    } else {
      toast.error(_updateAvailabilityResponse.response?.message);
    }
  }, [_updateAvailabilityResponse]);

  const onPageChange = (i) => {
    setPage({ ...page, page: i });
  };

  const onOrderClick = (key) => {
    const ignoreKey = ["action", "created_at", "current_place"];
    if (ignoreKey.includes(key)) {
      return;
    }
    const order = order_by == "DESC" ? "ASC" : "DESC";
    setOrderBy(order);
    dispatch(
      getSessionsAsync({
        page: page.page,
        page_size: page.page_size,
        order_by: key,
        order,
        filter,
      })
    );
  };

  const onTabChange = (status) => {
    setFilter(status);
    if (status == "GROUP") {
      setParticipantColumns([
        { key: "id", value: "ID" },
        { key: "first_name", value: "First Name" },
        { key: "last_name", value: "Last Name" },
        { key: "email", value: "Email" },
        { key: "created_at", value: "Enroll at" },
        { key: "action", value: "Action" },
      ]);
      setColumns([
        { key: "id", value: "ID" },
        { key: "seats", value: "Seats" },
        { key: "date", value: "Date" },
        { key: "total_participants", value: "Participants" },
        { key: "created_at", value: "Created At" },
        { key: "action", value: "Action" },
      ]);
      setPage({
        page: 1,
        page_size: 15,
      });
    } else if (status == "ONETOONE") {
      setParticipantColumns([
        { key: "id", value: "ID" },
        { key: "first_name", value: "First Name" },
        { key: "last_name", value: "Last Name" },
        { key: "email", value: "Email" },
        { key: "created_at", value: "Enroll at" },
      ]);
      setColumns([
        { key: "id", value: "ID" },
        { key: "date", value: "Date" },
        { key: "name", value: "Name" },
        { key: "email", value: "Email" },
        { key: "message", value: "Message" },
        { key: "created_at", value: "Created At" },
        { key: "action", value: "Action" },
      ]);
      setPage({
        page: 1,
        page_size: 15,
      });
    } else if (status == "HISTORY") {
      setParticipantColumns([
        { key: "id", value: "ID" },
        { key: "first_name", value: "First Name" },
        { key: "last_name", value: "Last Name" },
        { key: "email", value: "Email" },
        { key: "created_at", value: "Enroll at" },
      ]);
      setColumns([
        { key: "id", value: "ID" },
        { key: "date", value: "Date" },
        { key: "session_type", value: "Sesstion Type" },
        { key: "total_participants", value: "Participants" },
        { key: "name", value: "Name" },
        { key: "email", value: "Email" },
        { key: "message", value: "Message" },
        { key: "created_at", value: "Created At" },
      ]);
      dispatch(
        getSessionsAsync({
          filter,
          past_sessions: true,
        })
      );
    }
  };

  const onSessionAdd = (e) => {
    e.preventDefault();
    if (!session.seats || session.seats == "") {
      toast.error("Please enter seats");
    } else if (!session.date || session.date == "") {
      toast.error("Please select date");
    } else if (!session.session_type || session.session_type == "") {
      toast.error("Please select session typeP");
    } else {
      setShowAddModel(false);
      dispatch(addSessionAsync(session));
    }
  };

  const onSessionUpdate = (e) => {
    e.preventDefault();
    if (!session.seats || session.seats == "") {
      toast.error("Please enter seats");
    } else if (!session.date || session.date == "") {
      toast.error("Please select date");
    } else if (!session.session_type || session.session_type == "") {
      toast.error("Please select session typeP");
    } else {
      setShowUpdateModel(false);
      dispatch(
        updateSessionAsync({
          id: session.id,
          seats: session.seats,
          date: moment(session.date).format("YYYY-MM-DD HH:mm"),
          session_type: session.session_type,
        })
      );
    }
  };

  const onSessionDelete = (e) => {
    e.preventDefault();
    setShowDeleteModel(false);
    setShowEventModel(false)
    dispatch(
      deleteSessionAsync({
        id: session.id,
      })
    );
  };

  const onPaticipantDelete = (e) => {
    e.preventDefault();
    setShowDeleteParticipantModel(false);
    dispatch(
      deleteParticipantAsync({
        session_id: session.id,
        participant_id: participant.id,
      })
    );
  };

  const onSessionStart = (e) => {
    e.preventDefault();
    setShowStartModel(false);
    setShowEventModel(false)
    const newWindow = window.open(
      session.meeting_start_url,
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };

  const onTimingSet = (e) => {
    e.preventDefault();
    setShowTimingModel(false);
    dispatch(updateAvailabilityAsync(timing));
  };

  const DateTimePickerInput = React.forwardRef(({ value, onClick }, ref) => (
    <Form.Control
      className="mt-3 w-100"
      value={value}
      onClick={onClick}
      type="text"
      placeholder="Date"
    />
  ));

  const TimePickerInput = React.forwardRef(
    ({ value, onClick, disabled }, ref) => (
      <Form.Control
        className="w-100"
        disabled={disabled}
        value={value}
        onClick={onClick}
        type="text"
        placeholder="Date"
      />
    )
  );

  const eventStyleGetter = (event, start, end, isSelected) => {
    var backgroundColor = "#" + event.hexColor;
    var style = {
      backgroundColor: backgroundColor,
      color: "white",
    };
    return {
      style: style,
    };
  };

  const CustomEvent = (event) => {
    return (
      <span>
        <strong>{event.title}</strong>
      </span>
    );
  };

  const getView = () => {
    if (isCalanderView) {
      return (
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                Sessions
                <p className="text-muted">All Sessions</p>
              </Card.Header>
              <Card.Body>
                <Row className="ml-1">
                  <Col md="12" className="d-flex justify-content-end mb-2">
                    <span
                      onClick={handleAddModelShow}
                      to="#ecommerce-add"
                      className="btn btn-primary btn-rounded box-shadow btn-icon"
                    >
                      <i className="fa fa-plus" /> Add Session
                    </span>
                    <span
                      onClick={handleTimingModelShow}
                      to="#ecommerce-add"
                      className={`btn btn-primary btn-rounded box-shadow btn-icon ml-3`}
                    >
                      <i className="fa fa-plus" /> Set Timing
                    </span>

                    <span
                      onClick={handleViewSwitch}
                      to="#ecommerce-add"
                      className="btn btn-primary btn-rounded box-shadow btn-icon ml-3"
                    >
                      <i
                        className={
                          isCalanderView ? "fa fa-list" : "fa fa-calendar"
                        }
                      />{" "}
                      {isCalanderView ? "List View" : "Calander View"}
                    </span>
                  </Col>
                </Row>
                <div>
                  <Calendar
                    localizer={localizer}
                    showMultiDayTimes
                    step={60}
                    startAccessor="start"
                    endAccessor="end"
                    defaultView="month"
                    style={{ height: 600 }}
                    views={["month"]}
                    events={events}
                    popup
                    components={{
                      event: CustomEvent,
                    }}
                    onSelectEvent={(e) => {
                      handleEventModelShow(e);
                    }}
                    eventPropGetter={eventStyleGetter}
                    onNavigate={(e) => {
                      dispatch(
                        getSessionsAsync({
                          filter,
                          month: moment(e).format("YYYY-MM-DD"),
                        })
                      );
                    }}
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      );
    } else {
      return (
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                Sessions
                <p className="text-muted">All Sessions</p>
              </Card.Header>
              <Card.Body>
                <Row className="ml-1">
                  <Col>
                    <Tabs
                      onSelect={onTabChange}
                      defaultActiveKey="GROUP"
                      id="uncontrolled-tab-example"
                    >
                      <Tab eventKey="GROUP" title="Group"></Tab>
                      <Tab eventKey="ONETOONE" title="One To One"></Tab>
                      <Tab eventKey="HISTORY" title="History"></Tab>
                    </Tabs>
                  </Col>
                  <Col md="auto">
                    <span
                      onClick={handleAddModelShow}
                      to="#ecommerce-add"
                      className="btn btn-primary btn-rounded box-shadow btn-icon"
                    >
                      <i className="fa fa-plus" /> Add Session
                    </span>
                    <span
                      onClick={handleTimingModelShow}
                      to="#ecommerce-add"
                      className={`btn btn-primary btn-rounded box-shadow btn-icon ml-3 ${
                        filter === "ONETOONE" ? "" : "d-none"
                      }`}
                    >
                      <i className="fa fa-plus" /> Set Timing
                    </span>

                    <span
                      onClick={handleViewSwitch}
                      to="#ecommerce-add"
                      className="btn btn-primary btn-rounded box-shadow btn-icon ml-3"
                    >
                      <i
                        className={
                          isCalanderView ? "fa fa-list" : "fa fa-calendar"
                        }
                      />{" "}
                      {isCalanderView ? "List View" : "Calander View"}
                    </span>
                  </Col>
                </Row>
                <div className="table-responsive">
                  <Table
                    id="datatable1"
                    striped="columns"
                    className="table table-striped table-hover mt-4"
                  >
                    <thead>
                      <tr>
                        {columns.map((item) => {
                          return (
                            <th
                              className="text-center"
                              nowrap="nowrap"
                              role="button"
                              nowrap="nowrap"
                              className="text-center"
                              onClick={() => onOrderClick(item.key)}
                            >
                              <Row className="justify-content-md-center ml-2 mr-2">
                                <strong>{item.value}</strong>
                              </Row>
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {sessions.map((item) => {
                        return (
                          <tr>
                            <td>{item.id}</td>
                            {filter == "GROUP" && <td>{item.seats}</td>}
                            <td nowrap="nowrap">{item.date}</td>
                            {filter == "HISTORY" && (
                              <td>{item.session_type}</td>
                            )}
                            {(filter == "GROUP" || filter == "HISTORY") && (
                              <td>
                                {item.session_type == "ONETOONE"
                                  ? 1
                                  : filter == "HISTORY"
                                  ? item.participants?.length
                                  : item.total_participants}
                              </td>
                            )}

                            {(filter == "ONETOONE" || filter == "HISTORY") && (
                              <td>{`${
                                item.session_type == "GROUP"
                                  ? ""
                                  : item.participants?.length > 0
                                  ? item.participants[0].user.first_name
                                  : ""
                              } ${
                                item.session_type == "GROUP"
                                  ? ""
                                  : item.participants?.length > 0
                                  ? item.participants[0].user.last_name
                                  : ""
                              }`}</td>
                            )}
                            {(filter == "ONETOONE" || filter == "HISTORY") && (
                              <td>
                                {item.session_type == "GROUP"
                                  ? ""
                                  : item.participants?.length > 0
                                  ? item.participants[0].user.email
                                  : ""}
                              </td>
                            )}
                            {(filter == "ONETOONE" || filter == "HISTORY") && (
                              <td>{item.message}</td>
                            )}

                            <td nowrap="nowrap">{item.created_at}</td>
                            {filter != "HISTORY" && (
                              <td nowrap="nowrap" className="text-center">
                                <Button
                                  className="btn btn-sm btn-default"
                                  onClick={() => handleUpdateModelShow(item)}
                                >
                                  <i className="fa fa-eye" />
                                </Button>{" "}
                                <Button
                                  variant="primary"
                                  size="sm"
                                  onClick={() =>
                                    handleParticipantsModelShow(item)
                                  }
                                >
                                  <i className="fa fa-user" />
                                </Button>{" "}
                                <Button
                                  variant="success"
                                  size="sm"
                                  onClick={() => handleStartModelShow(item)}
                                >
                                  <i className="fa fa-rocket" />
                                </Button>{" "}
                                <Button
                                  variant="danger"
                                  size="sm"
                                  onClick={() => handleDeleteModelShow(item)}
                                >
                                  <i className="fa fa-trash" />
                                </Button>{" "}
                              </td>
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
                <Pagination>
                  {Array.from({ length: paging.total_pages }).map((item, i) => {
                    return (
                      <Pagination.Item
                        onClick={() => onPageChange(i + 1)}
                        key={i}
                        active={i + 1 === paging.currentPage}
                      >
                        {i + 1}
                      </Pagination.Item>
                    );
                  })}
                </Pagination>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      );
    }
  };

  return (
    <React.Fragment>
      {getView()}

      <Modal show={showAddModel} onHide={handleAddModelClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Session</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group>
                <Form.Control
                  value={session.seats}
                  onChange={(e) => {
                    setSession({ ...session, seats: e.target.value });
                  }}
                  type="number"
                  placeholder="Seats"
                />
                <div className="customDatePickerWidth">
                  <DatePicker
                    selected={moment(session.date).toDate()}
                    showTimeSelect
                    dateFormat="yyyy-MM-dd HH:mm"
                    customInput={<DateTimePickerInput />}
                    onChange={(date) => {
                      setSession({
                        ...session,
                        date: moment(date).format("YYYY-MM-DD HH:mm"),
                      });
                    }}
                  />
                </div>

                <Form.Control
                  as="select"
                  className="mt-3"
                  value={session.session_type}
                  onChange={(e) => {
                    setSession({ ...session, session_type: e.target.value });
                  }}
                >
                  <option value={"PLACEHOLDER"}>Session Type</option>
                  <option value="GROUP"> Group</option>
                  <option value="ONETOONE"> One To One</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleAddModelClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onSessionAdd}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showUpdateModel} onHide={handleUpdateModelClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Session</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group>
                <Form.Control
                  value={session.seats}
                  onChange={(e) => {
                    setSession({ ...session, seats: e.target.value });
                  }}
                  type="number"
                  placeholder="Seats"
                />
                <div className="customDatePickerWidth">
                  <DatePicker
                    selected={moment(session.date).toDate()}
                    showTimeSelect
                    dateFormat="yyyy-MM-dd HH:mm"
                    customInput={<DateTimePickerInput />}
                    onChange={(date) => {
                      setSession({
                        ...session,
                        date: moment(date).format("YYYY-MM-DD HH:mm"),
                      });
                    }}
                  />
                </div>

                <Form.Control
                  as="select"
                  className="mt-3"
                  value={session.session_type}
                  onChange={(e) => {
                    setSession({ ...session, session_type: e.target.value });
                  }}
                >
                  <option value={"PLACEHOLDER"}>Session Type</option>
                  <option value="GROUP"> Group</option>
                  <option value="ONETOONE"> One To One</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleUpdateModelClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onSessionUpdate}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeleteModel} onHide={handleDeleteModelClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete.?</p>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteModelClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onSessionDelete}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showStartModel} onHide={handleStartModelClose}>
        <Modal.Header closeButton>
          <Modal.Title>Start Session.?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to start session.?</p>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleStartModelClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onSessionStart}>
            Start
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="lg"
        show={showParticipantsModel}
        onHide={handleParticipantsModelClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Participants</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="table-responsive">
            <Table
              id="datatable1"
              striped="columns"
              className="table table-striped table-hover mt-4"
            >
              <thead>
                <tr>
                  {participantsColumns.map((item) => {
                    return (
                      <th role="button" nowrap="nowrap" className="text-center">
                        <Row className="justify-content-md-center ml-2 mr-2">
                          <strong>{item.value}</strong>
                        </Row>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {participants.map((item) => {
                  return (
                    <tr>
                      <td>{item.user.id}</td>
                      <td>{item.user.first_name}</td>
                      <td>{item.user.last_name}</td>
                      <td>{item.user.email}</td>
                      <td nowrap="nowrap">{item.created_at}</td>
                      {filter != "ONETOONE" && (
                        <td nowrap="nowrap" className="text-center">
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() =>
                              handleDeleteParticipantModelShow(item)
                            }
                          >
                            <i className="fa fa-trash" />
                          </Button>{" "}
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleParticipantsModelClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showDeleteParticipantModel}
        onHide={handleDeleteParticipantModelClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete.?</p>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleDeleteParticipantModelClose}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={onPaticipantDelete}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showTimingModel} onHide={handleTimingModelClose}>
        <Modal.Header closeButton>
          <Modal.Title>Set Timing</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group>
                <Form.Check
                  checked={timing.is_monday_holiday}
                  onChange={(e) => {
                    setTiming({
                      ...timing,
                      is_monday_holiday: e.target.checked,
                    });
                  }}
                  inline
                  label="Is Monday Holiday"
                  name="monday"
                />
                <Form.Row>
                  <Col>
                    <Form.Label column style={{ paddingLeft: 0 }}>
                      Mon Morning Start Time
                    </Form.Label>
                    <div className="customDatePickerWidth">
                      <DatePicker
                        disabled={timing.is_monday_holiday}
                        selected={moment(
                          timing.monday_morning_start_time,
                          "HH:mm"
                        ).toDate()}
                        minTime={moment("05:00", "HH:mm").toDate()}
                        maxTime={moment("12:00", "HH:mm").toDate()}
                        showTimeSelect
                        showTimeSelectOnly
                        dateFormat="HH:mm"
                        customInput={
                          <TimePickerInput
                            disabled={timing.is_monday_holiday}
                          />
                        }
                        onChange={(date) => {
                          setTiming({
                            ...timing,
                            monday_morning_start_time:
                              moment(date).format("HH:mm"),
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col>
                    <Form.Label column style={{ paddingLeft: 0 }}>
                      Mon Morning End Time
                    </Form.Label>
                    <div className="customDatePickerWidth">
                      <DatePicker
                        disabled={timing.is_monday_holiday}
                        selected={moment(
                          timing.monday_morning_end_time,
                          "HH:mm"
                        ).toDate()}
                        minTime={moment("05:00", "HH:mm").toDate()}
                        maxTime={moment("12:00", "HH:mm").toDate()}
                        showTimeSelect
                        showTimeSelectOnly
                        dateFormat="HH:mm"
                        customInput={
                          <TimePickerInput
                            disabled={timing.is_monday_holiday}
                          />
                        }
                        onChange={(date) => {
                          setTiming({
                            ...timing,
                            monday_morning_end_time:
                              moment(date).format("HH:mm"),
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col>
                    <Form.Label column style={{ paddingLeft: 0 }}>
                      Mon Afternoon Start Time
                    </Form.Label>
                    <div className="customDatePickerWidth">
                      <DatePicker
                        disabled={timing.is_monday_holiday}
                        selected={moment(
                          timing.monday_afternoon_start_time,
                          "HH:mm"
                        ).toDate()}
                        minTime={moment("12:00", "HH:mm").toDate()}
                        maxTime={moment("17:00", "HH:mm").toDate()}
                        showTimeSelect
                        showTimeSelectOnly
                        dateFormat="HH:mm"
                        customInput={
                          <TimePickerInput
                            disabled={timing.is_monday_holiday}
                          />
                        }
                        onChange={(date) => {
                          setTiming({
                            ...timing,
                            monday_afternoon_start_time:
                              moment(date).format("HH:mm"),
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col>
                    <Form.Label column style={{ paddingLeft: 0 }}>
                      Mon Afternoon End Time
                    </Form.Label>
                    <div className="customDatePickerWidth">
                      <DatePicker
                        disabled={timing.is_monday_holiday}
                        selected={moment(
                          timing.monday_afternoon_end_time,
                          "HH:mm"
                        ).toDate()}
                        minTime={moment("12:00", "HH:mm").toDate()}
                        maxTime={moment("17:00", "HH:mm").toDate()}
                        showTimeSelect
                        showTimeSelectOnly
                        dateFormat="HH:mm"
                        customInput={
                          <TimePickerInput
                            disabled={timing.is_monday_holiday}
                          />
                        }
                        onChange={(date) => {
                          setTiming({
                            ...timing,
                            monday_afternoon_end_time:
                              moment(date).format("HH:mm"),
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col>
                    <Form.Label column style={{ paddingLeft: 0 }}>
                      Mon Evening Start Time
                    </Form.Label>
                    <div className="customDatePickerWidth">
                      <DatePicker
                        disabled={timing.is_monday_holiday}
                        selected={moment(
                          timing.monday_evening_start_time,
                          "HH:mm"
                        ).toDate()}
                        minTime={moment("17:00", "HH:mm").toDate()}
                        maxTime={moment("22:00", "HH:mm").toDate()}
                        showTimeSelect
                        showTimeSelectOnly
                        dateFormat="HH:mm"
                        customInput={
                          <TimePickerInput
                            disabled={timing.is_monday_holiday}
                          />
                        }
                        onChange={(date) => {
                          setTiming({
                            ...timing,
                            monday_evening_start_time:
                              moment(date).format("HH:mm"),
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col>
                    <Form.Label column style={{ paddingLeft: 0 }}>
                      Mon Evening End Time
                    </Form.Label>
                    <div className="customDatePickerWidth">
                      <DatePicker
                        disabled={timing.is_monday_holiday}
                        selected={moment(
                          timing.monday_evening_end_time,
                          "HH:mm"
                        ).toDate()}
                        minTime={moment("17:00", "HH:mm").toDate()}
                        maxTime={moment("22:00", "HH:mm").toDate()}
                        showTimeSelect
                        showTimeSelectOnly
                        dateFormat="HH:mm"
                        customInput={
                          <TimePickerInput
                            disabled={timing.is_monday_holiday}
                          />
                        }
                        onChange={(date) => {
                          setTiming({
                            ...timing,
                            monday_evening_end_time:
                              moment(date).format("HH:mm"),
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Form.Row>

                <Form.Check
                  className="mt-3"
                  inline
                  checked={timing.is_tuesday_holiday}
                  onChange={(e) => {
                    setTiming({
                      ...timing,
                      is_tuesday_holiday: e.target.checked,
                    });
                  }}
                  label="Is Tuesday Holiday"
                  name="tuesday"
                />
                <Form.Row>
                  <Col>
                    <Form.Label column style={{ paddingLeft: 0 }}>
                      Tue Morning Start Time
                    </Form.Label>
                    <div className="customDatePickerWidth">
                      <DatePicker
                        disabled={timing.is_tuesday_holiday}
                        selected={moment(
                          timing.tuesday_morning_start_time,
                          "HH:mm"
                        ).toDate()}
                        minTime={moment("05:00", "HH:mm").toDate()}
                        maxTime={moment("12:00", "HH:mm").toDate()}
                        showTimeSelect
                        showTimeSelectOnly
                        dateFormat="HH:mm"
                        customInput={
                          <TimePickerInput
                            disabled={timing.is_tuesday_holiday}
                          />
                        }
                        onChange={(date) => {
                          setTiming({
                            ...timing,
                            tuesday_morning_start_time:
                              moment(date).format("HH:mm"),
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col>
                    <Form.Label column style={{ paddingLeft: 0 }}>
                      Tue Morning End Time
                    </Form.Label>
                    <div className="customDatePickerWidth">
                      <DatePicker
                        disabled={timing.is_tuesday_holiday}
                        selected={moment(
                          timing.tuesday_morning_end_time,
                          "HH:mm"
                        ).toDate()}
                        showTimeSelect
                        minTime={moment("05:00", "HH:mm").toDate()}
                        maxTime={moment("12:00", "HH:mm").toDate()}
                        showTimeSelectOnly
                        dateFormat="HH:mm"
                        customInput={
                          <TimePickerInput
                            disabled={timing.is_tuesday_holiday}
                          />
                        }
                        onChange={(date) => {
                          setTiming({
                            ...timing,
                            tuesday_morning_end_time:
                              moment(date).format("HH:mm"),
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col>
                    <Form.Label column style={{ paddingLeft: 0 }}>
                      Tue Afternoon Start Time
                    </Form.Label>
                    <div className="customDatePickerWidth">
                      <DatePicker
                        disabled={timing.is_tuesday_holiday}
                        selected={moment(
                          timing.tuesday_afternoon_start_time,
                          "HH:mm"
                        ).toDate()}
                        minTime={moment("12:00", "HH:mm").toDate()}
                        maxTime={moment("17:00", "HH:mm").toDate()}
                        showTimeSelect
                        showTimeSelectOnly
                        dateFormat="HH:mm"
                        customInput={
                          <TimePickerInput
                            disabled={timing.is_tuesday_holiday}
                          />
                        }
                        onChange={(date) => {
                          setTiming({
                            ...timing,
                            tuesday_afternoon_start_time:
                              moment(date).format("HH:mm"),
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col>
                    <Form.Label column style={{ paddingLeft: 0 }}>
                      Tue Afternoon End Time
                    </Form.Label>
                    <div className="customDatePickerWidth">
                      <DatePicker
                        disabled={timing.is_tuesday_holiday}
                        selected={moment(
                          timing.tuesday_afternoon_end_time,
                          "HH:mm"
                        ).toDate()}
                        minTime={moment("12:00", "HH:mm").toDate()}
                        maxTime={moment("17:00", "HH:mm").toDate()}
                        showTimeSelect
                        showTimeSelectOnly
                        dateFormat="HH:mm"
                        customInput={
                          <TimePickerInput
                            disabled={timing.is_tuesday_holiday}
                          />
                        }
                        onChange={(date) => {
                          setTiming({
                            ...timing,
                            tuesday_afternoon_end_time:
                              moment(date).format("HH:mm"),
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col>
                    <Form.Label column style={{ paddingLeft: 0 }}>
                      Tue Evening Start Time
                    </Form.Label>
                    <div className="customDatePickerWidth">
                      <DatePicker
                        disabled={timing.is_tuesday_holiday}
                        selected={moment(
                          timing.tuesday_evening_start_time,
                          "HH:mm"
                        ).toDate()}
                        minTime={moment("17:00", "HH:mm").toDate()}
                        maxTime={moment("22:00", "HH:mm").toDate()}
                        showTimeSelect
                        showTimeSelectOnly
                        dateFormat="HH:mm"
                        customInput={
                          <TimePickerInput
                            disabled={timing.is_tuesday_holiday}
                          />
                        }
                        onChange={(date) => {
                          setTiming({
                            ...timing,
                            tuesday_evening_start_time:
                              moment(date).format("HH:mm"),
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col>
                    <Form.Label column style={{ paddingLeft: 0 }}>
                      Tue Evening End Time
                    </Form.Label>
                    <div className="customDatePickerWidth">
                      <DatePicker
                        disabled={timing.is_tuesday_holiday}
                        selected={moment(
                          timing.tuesday_evening_end_time,
                          "HH:mm"
                        ).toDate()}
                        minTime={moment("17:00", "HH:mm").toDate()}
                        maxTime={moment("22:00", "HH:mm").toDate()}
                        showTimeSelect
                        showTimeSelectOnly
                        dateFormat="HH:mm"
                        customInput={
                          <TimePickerInput
                            disabled={timing.is_tuesday_holiday}
                          />
                        }
                        onChange={(date) => {
                          setTiming({
                            ...timing,
                            tuesday_evening_end_time:
                              moment(date).format("HH:mm"),
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Form.Row>

                <Form.Check
                  className="mt-3"
                  inline
                  checked={timing.is_wednesday_holiday}
                  onChange={(e) => {
                    setTiming({
                      ...timing,
                      is_wednesday_holiday: e.target.checked,
                    });
                  }}
                  label="Is Wednesday Holiday"
                  name="wednesday"
                />
                <Form.Row>
                  <Col>
                    <Form.Label column style={{ paddingLeft: 0 }}>
                      Wed Morning Start Time
                    </Form.Label>
                    <div className="customDatePickerWidth">
                      <DatePicker
                        disabled={timing.is_wednesday_holiday}
                        selected={moment(
                          timing.wednesday_morning_start_time,
                          "HH:mm"
                        ).toDate()}
                        showTimeSelect
                        showTimeSelectOnly
                        minTime={moment("05:00", "HH:mm").toDate()}
                        maxTime={moment("12:00", "HH:mm").toDate()}
                        dateFormat="HH:mm"
                        customInput={
                          <TimePickerInput
                            disabled={timing.is_wednesday_holiday}
                          />
                        }
                        onChange={(date) => {
                          setTiming({
                            ...timing,
                            wednesday_morning_start_time:
                              moment(date).format("HH:mm"),
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col>
                    <Form.Label column style={{ paddingLeft: 0 }}>
                      Wed Morning End Time
                    </Form.Label>
                    <div className="customDatePickerWidth">
                      <DatePicker
                        disabled={timing.is_wednesday_holiday}
                        selected={moment(
                          timing.wednesday_morning_end_time,
                          "HH:mm"
                        ).toDate()}
                        showTimeSelect
                        showTimeSelectOnly
                        minTime={moment("05:00", "HH:mm").toDate()}
                        maxTime={moment("12:00", "HH:mm").toDate()}
                        dateFormat="HH:mm"
                        customInput={
                          <TimePickerInput
                            disabled={timing.is_wednesday_holiday}
                          />
                        }
                        onChange={(date) => {
                          setTiming({
                            ...timing,
                            wednesday_morning_end_time:
                              moment(date).format("HH:mm"),
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col>
                    <Form.Label column style={{ paddingLeft: 0 }}>
                      Wed Afternoon Start Time
                    </Form.Label>
                    <div className="customDatePickerWidth">
                      <DatePicker
                        disabled={timing.is_wednesday_holiday}
                        selected={moment(
                          timing.wednesday_afternoon_start_time,
                          "HH:mm"
                        ).toDate()}
                        minTime={moment("12:00", "HH:mm").toDate()}
                        maxTime={moment("17:00", "HH:mm").toDate()}
                        showTimeSelect
                        showTimeSelectOnly
                        dateFormat="HH:mm"
                        customInput={
                          <TimePickerInput
                            disabled={timing.is_wednesday_holiday}
                          />
                        }
                        onChange={(date) => {
                          setTiming({
                            ...timing,
                            wednesday_afternoon_start_time:
                              moment(date).format("HH:mm"),
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col>
                    <Form.Label column style={{ paddingLeft: 0 }}>
                      Wed Afternoon End Time
                    </Form.Label>
                    <div className="customDatePickerWidth">
                      <DatePicker
                        disabled={timing.is_wednesday_holiday}
                        selected={moment(
                          timing.wednesday_afternoon_end_time,
                          "HH:mm"
                        ).toDate()}
                        minTime={moment("12:00", "HH:mm").toDate()}
                        maxTime={moment("17:00", "HH:mm").toDate()}
                        showTimeSelect
                        showTimeSelectOnly
                        dateFormat="HH:mm"
                        customInput={
                          <TimePickerInput
                            disabled={timing.is_wednesday_holiday}
                          />
                        }
                        onChange={(date) => {
                          setTiming({
                            ...timing,
                            wednesday_afternoon_end_time:
                              moment(date).format("HH:mm"),
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col>
                    <Form.Label column style={{ paddingLeft: 0 }}>
                      Wed Evening Start Time
                    </Form.Label>
                    <div className="customDatePickerWidth">
                      <DatePicker
                        disabled={timing.is_wednesday_holiday}
                        selected={moment(
                          timing.wednesday_evening_start_time,
                          "HH:mm"
                        ).toDate()}
                        minTime={moment("17:00", "HH:mm").toDate()}
                        maxTime={moment("22:00", "HH:mm").toDate()}
                        showTimeSelect
                        showTimeSelectOnly
                        dateFormat="HH:mm"
                        customInput={
                          <TimePickerInput
                            disabled={timing.is_wednesday_holiday}
                          />
                        }
                        onChange={(date) => {
                          setTiming({
                            ...timing,
                            wednesday_evening_start_time:
                              moment(date).format("HH:mm"),
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col>
                    <Form.Label column style={{ paddingLeft: 0 }}>
                      Wed Evening End Time
                    </Form.Label>
                    <div className="customDatePickerWidth">
                      <DatePicker
                        disabled={timing.is_wednesday_holiday}
                        selected={moment(
                          timing.wednesday_evening_end_time,
                          "HH:mm"
                        ).toDate()}
                        minTime={moment("17:00", "HH:mm").toDate()}
                        maxTime={moment("22:00", "HH:mm").toDate()}
                        showTimeSelect
                        showTimeSelectOnly
                        dateFormat="HH:mm"
                        customInput={
                          <TimePickerInput
                            disabled={timing.is_wednesday_holiday}
                          />
                        }
                        onChange={(date) => {
                          setTiming({
                            ...timing,
                            wednesday_evening_end_time:
                              moment(date).format("HH:mm"),
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Form.Row>

                <Form.Check
                  className="mt-3"
                  inline
                  checked={timing.is_thursday_holiday}
                  onChange={(e) => {
                    setTiming({
                      ...timing,
                      is_thursday_holiday: e.target.checked,
                    });
                  }}
                  label="Is Thursday Holiday"
                  name="thursday"
                />
                <Form.Row>
                  <Col>
                    <Form.Label column style={{ paddingLeft: 0 }}>
                      Thu Morning Start Time
                    </Form.Label>
                    <div className="customDatePickerWidth">
                      <DatePicker
                        disabled={timing.is_thursday_holiday}
                        selected={moment(
                          timing.thursday_morning_start_time,
                          "HH:mm"
                        ).toDate()}
                        showTimeSelect
                        showTimeSelectOnly
                        minTime={moment("05:00", "HH:mm").toDate()}
                        maxTime={moment("12:00", "HH:mm").toDate()}
                        dateFormat="HH:mm"
                        customInput={
                          <TimePickerInput
                            disabled={timing.is_thursday_holiday}
                          />
                        }
                        onChange={(date) => {
                          setTiming({
                            ...timing,
                            thursday_morning_start_time:
                              moment(date).format("HH:mm"),
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col>
                    <Form.Label column style={{ paddingLeft: 0 }}>
                      Thu Morning End Time
                    </Form.Label>
                    <div className="customDatePickerWidth">
                      <DatePicker
                        disabled={timing.is_thursday_holiday}
                        selected={moment(
                          timing.tuesday_morning_end_time,
                          "HH:mm"
                        ).toDate()}
                        showTimeSelect
                        minTime={moment("05:00", "HH:mm").toDate()}
                        maxTime={moment("12:00", "HH:mm").toDate()}
                        showTimeSelectOnly
                        dateFormat="HH:mm"
                        customInput={
                          <TimePickerInput
                            disabled={timing.is_thursday_holiday}
                          />
                        }
                        onChange={(date) => {
                          setTiming({
                            ...timing,
                            tuesday_morning_end_time:
                              moment(date).format("HH:mm"),
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col>
                    <Form.Label column style={{ paddingLeft: 0 }}>
                      Thu Afternoon Start Time
                    </Form.Label>
                    <div className="customDatePickerWidth">
                      <DatePicker
                        disabled={timing.is_thursday_holiday}
                        selected={moment(
                          timing.thursday_afternoon_start_time,
                          "HH:mm"
                        ).toDate()}
                        minTime={moment("12:00", "HH:mm").toDate()}
                        maxTime={moment("17:00", "HH:mm").toDate()}
                        showTimeSelect
                        showTimeSelectOnly
                        dateFormat="HH:mm"
                        customInput={
                          <TimePickerInput
                            disabled={timing.is_thursday_holiday}
                          />
                        }
                        onChange={(date) => {
                          setTiming({
                            ...timing,
                            thursday_afternoon_start_time:
                              moment(date).format("HH:mm"),
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col>
                    <Form.Label column style={{ paddingLeft: 0 }}>
                      Thu Afternoon End Time
                    </Form.Label>
                    <div className="customDatePickerWidth">
                      <DatePicker
                        disabled={timing.is_thursday_holiday}
                        selected={moment(
                          timing.thursday_afternoon_end_time,
                          "HH:mm"
                        ).toDate()}
                        minTime={moment("12:00", "HH:mm").toDate()}
                        maxTime={moment("17:00", "HH:mm").toDate()}
                        showTimeSelect
                        showTimeSelectOnly
                        dateFormat="HH:mm"
                        customInput={
                          <TimePickerInput
                            disabled={timing.is_thursday_holiday}
                          />
                        }
                        onChange={(date) => {
                          setTiming({
                            ...timing,
                            thursday_afternoon_end_time:
                              moment(date).format("HH:mm"),
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col>
                    <Form.Label column style={{ paddingLeft: 0 }}>
                      Thu Evening Start Time
                    </Form.Label>
                    <div className="customDatePickerWidth">
                      <DatePicker
                        disabled={timing.is_thursday_holiday}
                        selected={moment(
                          timing.thursday_evening_start_time,
                          "HH:mm"
                        ).toDate()}
                        minTime={moment("17:00", "HH:mm").toDate()}
                        maxTime={moment("22:00", "HH:mm").toDate()}
                        showTimeSelect
                        showTimeSelectOnly
                        dateFormat="HH:mm"
                        customInput={
                          <TimePickerInput
                            disabled={timing.is_thursday_holiday}
                          />
                        }
                        onChange={(date) => {
                          setTiming({
                            ...timing,
                            thursday_evening_start_time:
                              moment(date).format("HH:mm"),
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col>
                    <Form.Label column style={{ paddingLeft: 0 }}>
                      Thu Evening End Time
                    </Form.Label>
                    <div className="customDatePickerWidth">
                      <DatePicker
                        disabled={timing.is_thursday_holiday}
                        selected={moment(
                          timing.thursday_evening_end_time,
                          "HH:mm"
                        ).toDate()}
                        minTime={moment("17:00", "HH:mm").toDate()}
                        maxTime={moment("22:00", "HH:mm").toDate()}
                        showTimeSelect
                        showTimeSelectOnly
                        dateFormat="HH:mm"
                        customInput={
                          <TimePickerInput
                            disabled={timing.is_thursday_holiday}
                          />
                        }
                        onChange={(date) => {
                          setTiming({
                            ...timing,
                            thursday_evening_end_time:
                              moment(date).format("HH:mm"),
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Form.Row>

                <Form.Check
                  className="mt-3"
                  inline
                  checked={timing.is_friday_holiday}
                  onChange={(e) => {
                    setTiming({
                      ...timing,
                      is_friday_holiday: e.target.checked,
                    });
                  }}
                  label="Is Friday Holiday"
                  name="friday"
                />
                <Form.Row>
                  <Col>
                    <Form.Label column style={{ paddingLeft: 0 }}>
                      Fri Morning Start Time
                    </Form.Label>
                    <div className="customDatePickerWidth">
                      <DatePicker
                        disabled={timing.is_friday_holiday}
                        selected={moment(
                          timing.friday_morning_start_time,
                          "HH:mm"
                        ).toDate()}
                        minTime={moment("05:00", "HH:mm").toDate()}
                        maxTime={moment("12:00", "HH:mm").toDate()}
                        showTimeSelect
                        showTimeSelectOnly
                        dateFormat="HH:mm"
                        customInput={
                          <TimePickerInput
                            disabled={timing.is_friday_holiday}
                          />
                        }
                        onChange={(date) => {
                          setTiming({
                            ...timing,
                            friday_morning_start_time:
                              moment(date).format("HH:mm"),
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col>
                    <Form.Label column style={{ paddingLeft: 0 }}>
                      Fri Morning End Time
                    </Form.Label>
                    <div className="customDatePickerWidth">
                      <DatePicker
                        disabled={timing.is_friday_holiday}
                        selected={moment(
                          timing.friday_morning_end_time,
                          "HH:mm"
                        ).toDate()}
                        minTime={moment("05:00", "HH:mm").toDate()}
                        maxTime={moment("12:00", "HH:mm").toDate()}
                        showTimeSelect
                        showTimeSelectOnly
                        dateFormat="HH:mm"
                        customInput={
                          <TimePickerInput
                            disabled={timing.is_friday_holiday}
                          />
                        }
                        onChange={(date) => {
                          setTiming({
                            ...timing,
                            friday_morning_end_time:
                              moment(date).format("HH:mm"),
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col>
                    <Form.Label column style={{ paddingLeft: 0 }}>
                      Fri Afternoon Start Time
                    </Form.Label>
                    <div className="customDatePickerWidth">
                      <DatePicker
                        disabled={timing.is_friday_holiday}
                        selected={moment(
                          timing.friday_afternoon_start_time,
                          "HH:mm"
                        ).toDate()}
                        minTime={moment("12:00", "HH:mm").toDate()}
                        maxTime={moment("17:00", "HH:mm").toDate()}
                        showTimeSelect
                        showTimeSelectOnly
                        dateFormat="HH:mm"
                        customInput={
                          <TimePickerInput
                            disabled={timing.is_friday_holiday}
                          />
                        }
                        onChange={(date) => {
                          setTiming({
                            ...timing,
                            friday_afternoon_start_time:
                              moment(date).format("HH:mm"),
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col>
                    <Form.Label column style={{ paddingLeft: 0 }}>
                      Fri Afternoon End Time
                    </Form.Label>
                    <div className="customDatePickerWidth">
                      <DatePicker
                        disabled={timing.is_friday_holiday}
                        selected={moment(
                          timing.friday_afternoon_end_time,
                          "HH:mm"
                        ).toDate()}
                        minTime={moment("12:00", "HH:mm").toDate()}
                        maxTime={moment("17:00", "HH:mm").toDate()}
                        showTimeSelect
                        showTimeSelectOnly
                        dateFormat="HH:mm"
                        customInput={
                          <TimePickerInput
                            disabled={timing.is_friday_holiday}
                          />
                        }
                        onChange={(date) => {
                          setTiming({
                            ...timing,
                            friday_afternoon_end_time:
                              moment(date).format("HH:mm"),
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col>
                    <Form.Label column style={{ paddingLeft: 0 }}>
                      Fri Evening Start Time
                    </Form.Label>
                    <div className="customDatePickerWidth">
                      <DatePicker
                        disabled={timing.is_friday_holiday}
                        selected={moment(
                          timing.friday_evening_start_time,
                          "HH:mm"
                        ).toDate()}
                        minTime={moment("17:00", "HH:mm").toDate()}
                        maxTime={moment("22:00", "HH:mm").toDate()}
                        showTimeSelect
                        showTimeSelectOnly
                        dateFormat="HH:mm"
                        customInput={
                          <TimePickerInput
                            disabled={timing.is_friday_holiday}
                          />
                        }
                        onChange={(date) => {
                          setTiming({
                            ...timing,
                            friday_evening_start_time:
                              moment(date).format("HH:mm"),
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col>
                    <Form.Label column style={{ paddingLeft: 0 }}>
                      Fri Evening End Time
                    </Form.Label>
                    <div className="customDatePickerWidth">
                      <DatePicker
                        disabled={timing.is_friday_holiday}
                        selected={moment(
                          timing.friday_evening_end_time,
                          "HH:mm"
                        ).toDate()}
                        minTime={moment("17:00", "HH:mm").toDate()}
                        maxTime={moment("22:00", "HH:mm").toDate()}
                        showTimeSelect
                        showTimeSelectOnly
                        dateFormat="HH:mm"
                        customInput={
                          <TimePickerInput
                            disabled={timing.is_friday_holiday}
                          />
                        }
                        onChange={(date) => {
                          setTiming({
                            ...timing,
                            friday_evening_end_time:
                              moment(date).format("HH:mm"),
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Form.Row>

                <Form.Check
                  className="mt-3"
                  inline
                  checked={timing.is_saturday_holiday}
                  onChange={(e) => {
                    setTiming({
                      ...timing,
                      is_saturday_holiday: e.target.checked,
                    });
                  }}
                  label="Is Saturday Holiday"
                  name="saturday"
                />
                <Form.Row>
                  <Col>
                    <Form.Label column style={{ paddingLeft: 0 }}>
                      Sat Morning Start Time
                    </Form.Label>
                    <div className="customDatePickerWidth">
                      <DatePicker
                        disabled={timing.is_saturday_holiday}
                        selected={moment(
                          timing.saturday_morning_start_time,
                          "HH:mm"
                        ).toDate()}
                        minTime={moment("05:00", "HH:mm").toDate()}
                        maxTime={moment("12:00", "HH:mm").toDate()}
                        showTimeSelect
                        showTimeSelectOnly
                        dateFormat="HH:mm"
                        customInput={
                          <TimePickerInput
                            disabled={timing.is_saturday_holiday}
                          />
                        }
                        onChange={(date) => {
                          setSession({
                            ...timing,
                            saturday_morning_start_time:
                              moment(date).format("HH:mm"),
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col>
                    <Form.Label column style={{ paddingLeft: 0 }}>
                      Sat Morning End Time
                    </Form.Label>
                    <div className="customDatePickerWidth">
                      <DatePicker
                        disabled={timing.is_saturday_holiday}
                        selected={moment(
                          timing.saturday_morning_end_time,
                          "HH:mm"
                        ).toDate()}
                        minTime={moment("05:00", "HH:mm").toDate()}
                        maxTime={moment("12:00", "HH:mm").toDate()}
                        showTimeSelect
                        showTimeSelectOnly
                        dateFormat="HH:mm"
                        customInput={
                          <TimePickerInput
                            disabled={timing.is_saturday_holiday}
                          />
                        }
                        onChange={(date) => {
                          setTiming({
                            ...timing,
                            saturday_morning_end_time:
                              moment(date).format("HH:mm"),
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col>
                    <Form.Label column style={{ paddingLeft: 0 }}>
                      Sat Afternoon Start Time
                    </Form.Label>
                    <div className="customDatePickerWidth">
                      <DatePicker
                        disabled={timing.is_saturday_holiday}
                        selected={moment(
                          timing.saturday_afternoon_start_time,
                          "HH:mm"
                        ).toDate()}
                        minTime={moment("12:00", "HH:mm").toDate()}
                        maxTime={moment("17:00", "HH:mm").toDate()}
                        showTimeSelect
                        showTimeSelectOnly
                        dateFormat="HH:mm"
                        customInput={
                          <TimePickerInput
                            disabled={timing.is_saturday_holiday}
                          />
                        }
                        onChange={(date) => {
                          setTiming({
                            ...timing,
                            saturday_afternoon_start_time:
                              moment(date).format("HH:mm"),
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col>
                    <Form.Label column style={{ paddingLeft: 0 }}>
                      Sat Afternoon End Time
                    </Form.Label>
                    <div className="customDatePickerWidth">
                      <DatePicker
                        disabled={timing.is_saturday_holiday}
                        selected={moment(
                          timing.saturday_afternoon_end_time,
                          "HH:mm"
                        ).toDate()}
                        minTime={moment("12:00", "HH:mm").toDate()}
                        maxTime={moment("17:00", "HH:mm").toDate()}
                        showTimeSelect
                        showTimeSelectOnly
                        dateFormat="HH:mm"
                        customInput={
                          <TimePickerInput
                            disabled={timing.is_saturday_holiday}
                          />
                        }
                        onChange={(date) => {
                          setTiming({
                            ...timing,
                            saturday_afternoon_end_time:
                              moment(date).format("HH:mm"),
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col>
                    <Form.Label column style={{ paddingLeft: 0 }}>
                      Sat Evening Start Time
                    </Form.Label>
                    <div className="customDatePickerWidth">
                      <DatePicker
                        disabled={timing.is_saturday_holiday}
                        selected={moment(
                          timing.saturday_evening_start_time,
                          "HH:mm"
                        ).toDate()}
                        minTime={moment("17:00", "HH:mm").toDate()}
                        maxTime={moment("22:00", "HH:mm").toDate()}
                        showTimeSelect
                        showTimeSelectOnly
                        dateFormat="HH:mm"
                        customInput={
                          <TimePickerInput
                            disabled={timing.is_saturday_holiday}
                          />
                        }
                        onChange={(date) => {
                          setTiming({
                            ...timing,
                            saturday_evening_start_time:
                              moment(date).format("HH:mm"),
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col>
                    <Form.Label column style={{ paddingLeft: 0 }}>
                      Sat Evening End Time
                    </Form.Label>
                    <div className="customDatePickerWidth">
                      <DatePicker
                        disabled={timing.is_saturday_holiday}
                        selected={moment(
                          timing.saturday_evening_end_time,
                          "HH:mm"
                        ).toDate()}
                        minTime={moment("17:00", "HH:mm").toDate()}
                        maxTime={moment("22:00", "HH:mm").toDate()}
                        showTimeSelect
                        showTimeSelectOnly
                        dateFormat="HH:mm"
                        customInput={
                          <TimePickerInput
                            disabled={timing.is_saturday_holiday}
                          />
                        }
                        onChange={(date) => {
                          setTiming({
                            ...timing,
                            saturday_evening_end_time:
                              moment(date).format("HH:mm"),
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Form.Row>

                <Form.Check
                  className="mt-3"
                  inline
                  label="Is Sunday Holiday"
                  name="sunday"
                  checked={timing.is_sunday_holiday}
                  onChange={(e) => {
                    setTiming({
                      ...timing,
                      is_sunday_holiday: e.target.checked,
                    });
                  }}
                />
                <Form.Row>
                  <Col>
                    <Form.Label column style={{ paddingLeft: 0 }}>
                      Sun Morning Start Time
                    </Form.Label>
                    <div className="customDatePickerWidth">
                      <DatePicker
                        disabled={timing.is_sunday_holiday}
                        selected={moment(
                          timing.sunday_morning_start_time,
                          "HH:mm"
                        ).toDate()}
                        minTime={moment("05:00", "HH:mm").toDate()}
                        maxTime={moment("12:00", "HH:mm").toDate()}
                        showTimeSelect
                        showTimeSelectOnly
                        dateFormat="HH:mm"
                        customInput={
                          <TimePickerInput
                            disabled={timing.is_sunday_holiday}
                          />
                        }
                        onChange={(date) => {
                          setTiming({
                            ...timing,
                            sunday_morning_start_time:
                              moment(date).format("HH:mm"),
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col>
                    <Form.Label column style={{ paddingLeft: 0 }}>
                      Sunday Morning End Time
                    </Form.Label>
                    <div className="customDatePickerWidth">
                      <DatePicker
                        disabled={timing.is_sunday_holiday}
                        selected={moment(
                          timing.sunday_morning_end_time,
                          "HH:mm"
                        ).toDate()}
                        minTime={moment("05:00", "HH:mm").toDate()}
                        maxTime={moment("12:00", "HH:mm").toDate()}
                        showTimeSelect
                        showTimeSelectOnly
                        dateFormat="HH:mm"
                        customInput={
                          <TimePickerInput
                            disabled={timing.is_sunday_holiday}
                          />
                        }
                        onChange={(date) => {
                          setTiming({
                            ...timing,
                            sunday_morning_end_time:
                              moment(date).format("HH:mm"),
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col>
                    <Form.Label column style={{ paddingLeft: 0 }}>
                      Sun Afternoon Start Time
                    </Form.Label>
                    <div className="customDatePickerWidth">
                      <DatePicker
                        disabled={timing.is_sunday_holiday}
                        selected={moment(
                          timing.sunday_afternoon_start_time,
                          "HH:mm"
                        ).toDate()}
                        minTime={moment("12:00", "HH:mm").toDate()}
                        maxTime={moment("17:00", "HH:mm").toDate()}
                        showTimeSelect
                        showTimeSelectOnly
                        dateFormat="HH:mm"
                        customInput={
                          <TimePickerInput
                            disabled={timing.is_sunday_holiday}
                          />
                        }
                        onChange={(date) => {
                          setTiming({
                            ...timing,
                            sunday_afternoon_start_time:
                              moment(date).format("HH:mm"),
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col>
                    <Form.Label column style={{ paddingLeft: 0 }}>
                      Sunday Afternoon End Time
                    </Form.Label>
                    <div className="customDatePickerWidth">
                      <DatePicker
                        disabled={timing.is_sunday_holiday}
                        selected={moment(
                          timing.sunday_afternoon_end_time,
                          "HH:mm"
                        ).toDate()}
                        minTime={moment("12:00", "HH:mm").toDate()}
                        maxTime={moment("17:00", "HH:mm").toDate()}
                        showTimeSelect
                        showTimeSelectOnly
                        dateFormat="HH:mm"
                        customInput={
                          <TimePickerInput
                            disabled={timing.is_sunday_holiday}
                          />
                        }
                        onChange={(date) => {
                          setTiming({
                            ...timing,
                            sunday_afternoon_end_time:
                              moment(date).format("HH:mm"),
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col>
                    <Form.Label column style={{ paddingLeft: 0 }}>
                      Sunday Evening Start Time
                    </Form.Label>
                    <div className="customDatePickerWidth">
                      <DatePicker
                        disabled={timing.is_sunday_holiday}
                        selected={moment(
                          timing.sunday_evening_start_time,
                          "HH:mm"
                        ).toDate()}
                        minTime={moment("17:00", "HH:mm").toDate()}
                        maxTime={moment("22:00", "HH:mm").toDate()}
                        showTimeSelect
                        showTimeSelectOnly
                        dateFormat="HH:mm"
                        customInput={
                          <TimePickerInput
                            disabled={timing.is_sunday_holiday}
                          />
                        }
                        onChange={(date) => {
                          setTiming({
                            ...timing,
                            sunday_evening_start_time:
                              moment(date).format("HH:mm"),
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col>
                    <Form.Label column style={{ paddingLeft: 0 }}>
                      Sunday Evening End Time
                    </Form.Label>
                    <div className="customDatePickerWidth">
                      <DatePicker
                        disabled={timing.is_sunday_holiday}
                        selected={moment(
                          timing.sunday_evening_end_time,
                          "HH:mm"
                        ).toDate()}
                        minTime={moment("17:00", "HH:mm").toDate()}
                        maxTime={moment("22:00", "HH:mm").toDate()}
                        showTimeSelect
                        showTimeSelectOnly
                        dateFormat="HH:mm"
                        customInput={
                          <TimePickerInput
                            disabled={timing.is_sunday_holiday}
                          />
                        }
                        onChange={(date) => {
                          setTiming({
                            ...timing,
                            sunday_evening_end_time:
                              moment(date).format("HH:mm"),
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Form.Row>
              </Form.Group>
            </Col>
          </Row>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleTimingModelClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onTimingSet}>
            Set Timing
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal size="lg" show={showEventModel} onHide={handleEventModelClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {session.session_type == "GROUP"
              ? "Group session"
              : "One to One session"}
            on {session.date}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>Participants</span>
          <div className="table-responsive">
            <Table
              id="datatable1"
              striped="columns"
              className="table table-striped table-hover mt-4"
            >
              <thead>
                <tr>
                  {participantsColumns.map((item) => {
                    return (
                      <th role="button" nowrap="nowrap" className="text-center">
                        <Row className="justify-content-md-center ml-2 mr-2">
                          <strong>{item.value}</strong>
                        </Row>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {participants.map((item) => {
                  return (
                    <tr>
                      <td>{item.user.id}</td>
                      <td>{item.user.first_name}</td>
                      <td>{item.user.last_name}</td>
                      <td>{item.user.email}</td>
                      <td nowrap="nowrap">{item.created_at}</td>
                      {filter != "ONETOONE" && (
                        <td nowrap="nowrap" className="text-center">
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() =>
                              handleDeleteParticipantModelShow(item)
                            }
                          >
                            <i className="fa fa-trash" />
                          </Button>{" "}
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled = {moment(session.date, "YYYY-MM-DD hh:mm a").isBefore()}
            variant="primary"
            onClick={() => {
              handleStartModelShow(session);
            }}
          >
            Start Session
          </Button>
          <Button
          disabled = {moment(session.date, "YYYY-MM-DD hh:mm a").isBefore()}
            variant="danger"
            onClick={() => {
              handleDeleteModelShow(session);
            }}
          >
            Delete Session
          </Button>
          <Button variant="secondary" onClick={handleEventModelClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
export default BackendLayout(Sessions);
