// AuthContext.jsx
import React, { createContext, useContext, useState } from "react";
import { getUser, setUser, removeUser } from "./utils";

const AuthContext = createContext();

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const data = getUser();
    return data;
  });

  const login = (data) => {
    setUser(data);
    setIsAuthenticated(true);
  };

  const logout = () => {
    removeUser();
    setIsAuthenticated(false);
  };

  const contextValue = {
    isAuthenticated,
    login,
    logout,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
