import React, { useEffect, useState } from "react";
import "./index.scss";
import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Pagination,
  Modal,
  InputGroup,
  Form,
  Image,
} from "react-bootstrap";
import BackendLayout from "../../layouts/backend-layout";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import {
  getFaqCategoriesAsync,
  faqCategoriesResponse,
  getFaqAsync,
  faqResponse,
  updateFAQAsync,
  faqUpdateResponse,
  deleteFAQAsync,
  faqDeleteResponse,
  addFAQAsync,
  faqAddResponse,
  resetAsync,
} from "../../api/slices/cms.slice";
import { toast } from "react-toastify";

function FAQ(props) {
  const { setLoading } = props;
  const dispatch = useDispatch();
  const _faqResponse = useSelector(faqResponse);
  const _faqUpdateResponse = useSelector(faqUpdateResponse);
  const _faqDeleteResponse = useSelector(faqDeleteResponse);
  const _faqAddResponse = useSelector(faqAddResponse);
  const _faqCategoriesResponse = useSelector(faqCategoriesResponse);

  const [index, setIndex] = useState();
  const [page, setPage] = useState({
    page: 1,
    page_size: 15,
  });
  const [faq, setFaq] = useState({});
  const [order_by, setOrderBy] = useState("DESC");
  const [query, setQuery] = useState();
  const [faqs, setFAQs] = useState([]);
  const [paging, setPaging] = useState([]);
  const [categories, setCategories] = useState([]);

  const [showAddModel, setShowAddModel] = useState(false);
  const handleAddModelClose = () => {
    setShowAddModel(false);
  };
  const handleAddModelShow = () => {
    setFaq("");
    setShowAddModel(true);
  };

  const [showEditModel, setShowEditModel] = useState(false);
  const handleEditModelClose = () => {
    setShowEditModel(false);
  };
  const handleEditModelShow = (index) => {
    setIndex(index);
    setFaq({ ...faqs[index], category_id: faqs[index].category.id });
    setShowEditModel(true);
  };

  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const handleDeleteModelClose = () => {
    setShowDeleteModel(false);
  };
  const handleDeleteModelShow = (index) => {
    setIndex(index);
    setShowDeleteModel(true);
  };

  const columns = [
    { key: "id", value: "ID" },
    { key: "question", value: "Question" },
    { key: "answer", value: "Answer" },
    { key: "language", value: "Language" },
    { key: "category_id", value: "Category" },
    { key: "created_at", value: "Created At" },
    { key: "active", value: "Active" },
    { key: "action", value: "Action" },
  ];

  useEffect(() => {
    dispatch(getFaqAsync({ page: page.page, page_size: page.page_size }));
    dispatch(getFaqCategoriesAsync({ page: 1, page_size: 100 }));
  }, [page]);

  useEffect(() => {
    setLoading(_faqResponse.isLoading);
    if (_faqResponse.response && _faqResponse.response.status === 200) {
      window.scrollTo(0, 0);
      setFAQs(_faqResponse.response.data.faqs);
      setPaging(_faqResponse.response.data.paging);
    } else {
      toast.error(_faqResponse.response?.message);
    }
  }, [_faqResponse]);

  useEffect(() => {
    setLoading(_faqUpdateResponse.isLoading);
    if (
      _faqUpdateResponse.response &&
      _faqUpdateResponse.response.status === 200
    ) {
      setFAQs((categories) => {
        const updatedData = [...categories];
        updatedData[index] = {
          ...updatedData[index],
          ..._faqUpdateResponse.response.data,
        };
        return updatedData;
      });
    } else {
      toast.error(_faqUpdateResponse.response?.message);
    }
  }, [_faqUpdateResponse]);

  useEffect(() => {
    setLoading(_faqAddResponse.isLoading);
    if (_faqAddResponse.response && _faqAddResponse.response.status === 200) {
      setFaq("");
      dispatch(getFaqAsync({ page: page.page, page_size: page.page_size }));
    } else {
      toast.error(_faqAddResponse.response?.message);
    }
  }, [_faqAddResponse]);

  useEffect(() => {
    setLoading(_faqDeleteResponse.isLoading);
    if (
      _faqDeleteResponse.response &&
      _faqDeleteResponse.response.status === 200
    ) {
      setFAQs((categories) => {
        const updatedData = [...categories];
        updatedData.splice(index, 1);
        return updatedData;
      });
    } else {
      toast.error(_faqDeleteResponse.response?.message);
    }
  }, [_faqDeleteResponse]);

  useEffect(() => {
    setLoading(_faqCategoriesResponse.isLoading);
    if (
      _faqCategoriesResponse.response &&
      _faqCategoriesResponse.response.status === 200
    ) {
      setCategories(_faqCategoriesResponse.response.data.categories);
    } else {
      toast.error(_faqCategoriesResponse.response?.message);
    }
  }, [_faqCategoriesResponse]);

  const onPageChange = (i) => {
    setPage({ ...page, page: i });
  };

  const onOrderClick = (key) => {
    const ignoreKey = ["action", "updated_at"];
    if (ignoreKey.includes(key)) {
      return;
    }
    const order = order_by == "DESC" ? "ASC" : "DESC";
    setOrderBy(order);
    dispatch(
      getFaqAsync({
        page: page.page,
        page_size: page.page_size,
        order_by: key,
        order,
      })
    );
  };

  const onQueryChange = (e) => {
    setQuery(e.target.value);
    if (e.target.value == "") {
      dispatch(
        getFaqAsync({
          page: page.page,
          page_size: page.page_size,
        })
      );
    }
  };

  const onSearch = () => {
    dispatch(
      getFaqAsync({
        page: page.page,
        page_size: page.page_size,
        search: query,
      })
    );
  };

  const onDelete = () => {
    setShowDeleteModel(false);
    const data = {
      id: faqs[index].id,
    };
    dispatch(deleteFAQAsync(data));
  };

  const onAddFAQ = () => {
    setShowAddModel(false);
    dispatch(addFAQAsync(faq));
  };

  const onEditFAQ = () => {
    setShowEditModel(false);
    const data = {
      id: faq.id,
      question: faq.question,
      answer: faq.answer,
      category_id: faq.category_id,
      language: faq.language,
    };
    dispatch(updateFAQAsync(data));
  };

  const handleActiveChange = (index) => {
    setIndex(index);
    dispatch(
      updateFAQAsync({
        id: faqs[index].id,
        active: !faqs[index].active,
      })
    );
  };

  return (
    <React.Fragment>
      <Row>
        <Col md="12">
          <Card>
            <Card.Header>
              FAQ's
              <p className="text-muted">All FAQ's</p>
            </Card.Header>
            <Card.Body>
              <Row className="ml-1">
                <Col>
                  <span
                    onClick={handleAddModelShow}
                    to="#ecommerce-add"
                    className="btn btn-primary btn-rounded box-shadow btn-icon"
                  >
                    <i className="fa fa-plus" /> Add FAQ
                  </span>
                </Col>
                <Col md="auto">
                  <div className="mb-2">
                    <InputGroup>
                      <Form.Control
                        value={query}
                        onChange={onQueryChange}
                        type="text"
                        placeholder="Search"
                      />
                      <div className="input-group-btn">
                        <Button variant="primary" onClick={onSearch}>
                          <i className="fa fa-search" />
                        </Button>
                      </div>
                    </InputGroup>
                  </div>
                </Col>
              </Row>

              <div className="table-responsive">
                <Table
                  id="datatable1"
                  striped="columns"
                  className="table table-striped table-hover mt-4"
                >
                  <thead>
                    <tr>
                      {columns.map((item) => {
                        return (
                          <th
                            role="button"
                            className="text-center"
                            onClick={() => onOrderClick(item.key)}
                          >
                            <Row className="justify-content-md-center ml-2 mr-2">
                              <strong>{item.value}</strong>
                            </Row>
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {faqs.map((item, i) => {
                      return (
                        <tr className="text-center">
                          <td>{item.id}</td>
                          <td>{item.question}</td>
                          <td>{item.answer}</td>
                          <td>{item.language}</td>
                          <td>{item.category.name}</td>
                          <td>{item.created_at}</td>
                          <td>
                            <div className="custom-control custom-switch">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id={`switch_${i}`}
                                checked={item.active}
                                readOnly
                                onChange={() => {
                                  handleActiveChange(i);
                                }}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={`switch_${i}`}
                              />
                            </div>
                          </td>
                          <td nowrap="nowrap" className="text-center">
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditModelShow(i)}
                            >
                              <i className="fa fa-edit" />
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteModelShow(i)}
                            >
                              <i className="fa fa-trash" />
                            </Button>{" "}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
              <Pagination>
                {Array.from({ length: paging.total_pages }).map((item, i) => {
                  return (
                    <Pagination.Item
                      onClick={() => onPageChange(i + 1)}
                      key={i}
                      active={i + 1 === paging.currentPage}
                    >
                      {i + 1}
                    </Pagination.Item>
                  );
                })}
              </Pagination>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal show={showEditModel} onHide={handleEditModelClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit FAQ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group>
                <Form.Control
                  value={faq.question}
                  onChange={(e) => {
                    setFaq({ ...faq, question: e.target.value });
                  }}
                  type="text"
                  placeholder="Question"
                />

                <Form.Control
                  className="mt-3"
                  value={faq.answer}
                  onChange={(e) => {
                    setFaq({ ...faq, answer: e.target.value });
                  }}
                  type="text"
                  placeholder="Answer"
                />

                <Form.Control
                  as="select"
                  className="mt-3"
                  value={faq.category_id}
                  onChange={(e) => {
                    setFaq({ ...faq, category_id: e.target.value });
                  }}
                >
                  <option value={"PLACEHOLDER"}>Select Category</option>
                  {categories.map((item) => {
                    return <option value={item.id}> {item.name}</option>;
                  })}
                </Form.Control>

                <Form.Control
                  as="select"
                  className="mt-3"
                  value={faq.language}
                  onChange={(e) => {
                    setFaq({ ...faq, language: e.target.value });
                  }}
                >
                  <option value={"PLACEHOLDER"}>Select Language</option>
                  <option value={"ENGLISH"}>English</option>
                  <option value={"MYANMAR"}>Myanmar</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEditModelClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              if (faq == "") {
                toast.error("Please enter faq name");
              } else {
                onEditFAQ();
              }
            }}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showAddModel} onHide={handleAddModelClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add FAQ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group>
                <Form.Control
                  value={faq.question}
                  onChange={(e) => {
                    setFaq({ ...faq, question: e.target.value });
                  }}
                  type="text"
                  placeholder="Question"
                />

                <Form.Control
                  className="mt-3"
                  value={faq.answer}
                  onChange={(e) => {
                    setFaq({ ...faq, answer: e.target.value });
                  }}
                  type="text"
                  placeholder="Answer"
                />

                <Form.Control
                  as="select"
                  className="mt-3"
                  value={faq.category_id}
                  onChange={(e) => {
                    setFaq({ ...faq, category_id: e.target.value });
                  }}
                >
                  <option value={"PLACEHOLDER"}>Select Category</option>
                  {categories.map((item) => {
                    return <option value={item.id}> {item.name}</option>;
                  })}
                </Form.Control>

                <Form.Control
                  as="select"
                  className="mt-3"
                  value={faq.language}
                  onChange={(e) => {
                    setFaq({ ...faq, language: e.target.value });
                  }}
                >
                  <option value={"PLACEHOLDER"}>Select Language</option>
                  <option value={"ENGLISH"}>English</option>
                  <option value={"MYANMAR"}>Myanmar</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleAddModelClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              if (!faq.question || faq.question == "") {
                toast.error("Please enter FAQ question");
              } else if (!faq.answer || faq.answer == "") {
                toast.error("Please enter FAQ answer");
              } else if (!faq.category_id || faq.category_id == "") {
                toast.error("Please select FAQ category");
              } else if (!faq.language || faq.language == "") {
                toast.error("Please select FAQ language");
              } else {
                onAddFAQ();
              }
            }}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeleteModel} onHide={handleDeleteModelClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete.?</p>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteModelClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={(e) => {
              onDelete();
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
export default BackendLayout(FAQ);
