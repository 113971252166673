import CryptoJS from "crypto-js";

export const setUser = (data) => {
  const info = encodeURIComponent(
    CryptoJS.AES.encrypt(JSON.stringify(data), "TCDL#$#123").toString()
  );
  localStorage.setItem("data", info);
};

export const getUser = () => {
  const data = localStorage.getItem("data");
  if (data) {
    const bytes = CryptoJS.AES.decrypt(decodeURIComponent(data), "TCDL#$#123");
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  return null;
};

export const removeUser = () => {
  localStorage.removeItem("data");
};
