import React from "react";
import { Link } from "react-router-dom";
import { Dropdown, Image } from "react-bootstrap";
import "./style.scss";
import { useAuth } from "../../utils/auth_context";
import { useNavigate } from "react-router-dom";

const TopbarUserProfile = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  const onLogout = () => {
    logout();
    navigate("/");
  };

  return (
    <React.Fragment>
      <Dropdown as="li" className="avtar-dropdown">
        <Dropdown.Toggle>
          <Image
            src="/assets/img/avtar-2.png"
            className="mr-1"
            roundedCircle
            width={30}
          />
          John Doe
        </Dropdown.Toggle>
        <Dropdown.Menu as="ul" className={`top-dropdown`}>
          <Dropdown.Item as="li">
            <Link onClick={onLogout}>
              <i className="icon-logout" />
              Logout
            </Link>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </React.Fragment>
  );
};

export default TopbarUserProfile;
