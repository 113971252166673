import React, { useEffect, useState } from "react";
import "./index.scss";
import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Pagination,
  Modal,
  Container,
  InputGroup,
  Form,
} from "react-bootstrap";
import BackendLayout from "../../layouts/backend-layout";
import FileSaver from 'file-saver';
import { useSelector, useDispatch } from "react-redux";
import {
  getUsersAsync,
  exportUsersAsync,
  updateUserAsync,
  usersResponse,
  updateUserResponse,
  exportUsersResponse,
  resetAsync,
} from "../../api/slices/users.slice";
import { toast } from "react-toastify";

function Users(props) {
  const { setLoading } = props;
  const dispatch = useDispatch();
  const _usersResponse = useSelector(usersResponse);
  const _updateUserResponse = useSelector(updateUserResponse);
  const _exportUsersResponse = useSelector(exportUsersResponse);

  const [showDetailsModel, setShowDetailsModel] = React.useState(false);
  const handleDetailModelClose = () => setShowDetailsModel(false);
  const handleDetailModelShow = (user) => {
    setUser(user);
    setShowDetailsModel(true);
  };

  const [showDeactivateModel, setShowDeactivateModel] = React.useState(false);
  const handleDeactivateModelClose = () => setShowDeactivateModel(false);
  const handleDeactivateModelShow = (user) => {
    setUser(user);
    setShowDeactivateModel(true);
  };

  const [showEditModel, setShowEditModel] = React.useState(false);
  const handleEditModelClose = () => setShowEditModel(false);
  const handleEditModelShow = (user) => {
    setUpdateUser({
      ...user,
    });
    setShowEditModel(true);
  };

  const [page, setPage] = useState({
    page: 1,
    page_size: 15,
  });
  const [users, setUsers] = useState([]);
  const [order_by, setOrderBy] = useState("DESC");
  const [query, setQuery] = useState();
  const [user, setUser] = useState();
  const [updateUser, setUpdateUser] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    birth_date: "",
    birth_time: "",
    birth_place: "",
    current_place: "",
    gender: "",
    email: "",
  });
  const [paging, setPaging] = useState([]);

  const columns = [
    { key: "id", value: "ID" },
    { key: "first_name", value: "First Name" },
    { key: "middle_name", value: "Middle Name" },
    { key: "last_name", value: "Last Name" },
    { key: "email", value: "Email" },
    { key: "gender", value: "Gender" },
    { key: "current_place", value: "Current Place" },
    { key: "created_at", value: "Created At" },
    { key: "action", value: "Action" },
  ];

  useEffect(() => {
    dispatch(getUsersAsync({ page: page.page, page_size: page.page_size }));
  }, [page]);

  useEffect(() => {
    setLoading(_usersResponse.isLoading);
    if (_usersResponse.response && _usersResponse.response.status === 200) {
      window.scrollTo(0, 0);
      setUsers(_usersResponse.response.data.users);
      setPaging(_usersResponse.response.data.paging);
    } else {
      toast.error(_usersResponse.response?.message);
    }
  }, [_usersResponse]);

  useEffect(() => {
    setLoading(_updateUserResponse.isLoading);
    if (
      _updateUserResponse.response &&
      _updateUserResponse.response.status === 200
    ) {
      toast.success(_updateUserResponse.response?.message);
      dispatch(getUsersAsync({ page: page.page, page_size: page.page_size }));
    } else {
      toast.error(_updateUserResponse.response?.message);
    }
  }, [_updateUserResponse]);

  useEffect(() => {
    setLoading(_exportUsersResponse.isLoading);
    if (_exportUsersResponse.response) {
      const blob = new Blob([_exportUsersResponse.response], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      FileSaver.saveAs(blob, 'Users-Reports.xlsx');
      toast.success("File exported successfully");
    } else {
      toast.error(_exportUsersResponse.response?.message);
    }
  }, [_exportUsersResponse]);

  const onPageChange = (i) => {
    setPage({ ...page, page: i });
  };
  const onOrderClick = (key) => {
    const ignoreKey = ["action", "created_at", "current_place"];
    if (ignoreKey.includes(key)) {
      return;
    }
    const order = order_by == "DESC" ? "ASC" : "DESC";
    setOrderBy(order);
    dispatch(
      getUsersAsync({
        page: page.page,
        page_size: page.page_size,
        order_by: key,
        order,
      })
    );
  };

  const onExportClicked = (e) => {
    dispatch(exportUsersAsync({}));
  };

  const onQueryChange = (e) => {
    setQuery(e.target.value);
    if (e.target.value == "") {
      dispatch(
        getUsersAsync({
          page: page.page,
          page_size: page.page_size,
        })
      );
    }
  };

  const onSearch = () => {
    dispatch(
      getUsersAsync({
        page: page.page,
        page_size: page.page_size,
        search: query,
      })
    );
  };

  const onDeativateUser = () => {
    setShowDeactivateModel(false);
    dispatch(
      updateUserAsync({
        id: user.id,
        active: !user.active,
      })
    );
  };

  const onUpdateUser = () => {
    handleEditModelClose(false);
    const data = updateUser;
    if (!data.middle_name) {
      delete data.middle_name;
    }
    delete data.suspended;
    delete data.is_verified;
    delete data.created_at;
    delete data.updated_at;
    dispatch(
      updateUserAsync({
        ...updateUser,
      })
    );
  };

  const onUpdateChange = (data) => {
    const user = { ...updateUser, ...data };
    setUpdateUser(user);
  };

  return (
    <React.Fragment>
      <Row>
        <Col md="12">
          <Card>
            <Card.Header>
              Users
              <p className="text-muted">All TCDL Users</p>
            </Card.Header>
            <Card.Body>
              <Row className="ml-1 mb-1">
                <Col>
                  <span
                    onClick={onExportClicked}
                    className="btn btn-primary btn-rounded box-shadow btn-icon"
                  >
                    <i className="fa fa-file-excel-o" /> Export
                  </span>
                </Col>
                <Col md="auto">
                  <InputGroup>
                    <Form.Control
                      value={query}
                      onChange={onQueryChange}
                      type="text"
                      placeholder="Search"
                    />
                    <div className="input-group-btn">
                      <Button variant="primary" onClick={onSearch}>
                        <i className="fa fa-search" />
                      </Button>
                    </div>
                  </InputGroup>
                </Col>
              </Row>
              <Table
                id="datatable1"
                striped="columns"
                className="table table-striped table-responsive nowrap table-hover"
              >
                <thead>
                  <tr>
                    {columns.map((item) => {
                      return (
                        <th
                          role="button"
                          nowrap="nowrap"
                          className="text-center"
                          onClick={() => onOrderClick(item.key)}
                        >
                          <Row className="justify-content-md-center ml-2 mr-2">
                            <strong>{item.value}</strong>
                          </Row>
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {users.map((item) => {
                    return (
                      <tr>
                        <td>{item.id}</td>
                        <td>{item.first_name}</td>
                        <td>{item.middle_name || "N/A"}</td>
                        <td>{item.last_name}</td>
                        <td nowrap="nowrap">{item.email}</td>
                        <td>{item.gender}</td>
                        <td>{item.current_place}</td>
                        <td nowrap="nowrap">{item.created_at}</td>
                        <td nowrap="nowrap" className="text-center">
                          <Button
                            className="btn btn-sm btn-default"
                            onClick={() => handleDetailModelShow(item)}
                          >
                            <i className="fa fa-eye" />
                          </Button>{" "}
                          <Button
                            variant="success"
                            size="sm"
                            onClick={() => handleEditModelShow(item)}
                          >
                            <i className="fa fa-edit" />
                          </Button>{" "}
                          <Button
                            variant={item.active ? "danger" : "primary"}
                            size="sm"
                            onClick={() => handleDeactivateModelShow(item)}
                          >
                            {item.active ? (
                              <i className="fa fa-trash" />
                            ) : (
                              <i className="fa fa-undo" />
                            )}
                          </Button>{" "}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <Pagination>
                {Array.from({ length: paging.total_pages }).map((item, i) => {
                  return (
                    <Pagination.Item
                      onClick={() => onPageChange(i + 1)}
                      key={i}
                      active={i + 1 === paging.currentPage}
                    >
                      {i + 1}
                    </Pagination.Item>
                  );
                })}
              </Pagination>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal centered show={showDetailsModel} onHide={handleDetailModelClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="white-bg">
              <div className="padding-20 text-center">
                <img
                  alt="Profile "
                  width={140}
                  className="rounded-circle mar-btm margin-b-10 circle-border "
                  src={
                    user?.gender == "MALE"
                      ? "/assets/img/avtar-2.png"
                      : "/assets/img/avtar-1.png"
                  }
                />
                <p className="lead font-500 margin-b-0">
                  {user?.first_name}{" "}
                  {user?.middle_name ? user?.middle_name : ""} {user?.last_name}
                </p>
                <p
                  style={{ fontSize: "15px" }}
                  className="lead font-100 margin-b-0"
                >
                  {user?.email}
                </p>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body closeButton>
          <div className="white-bg friends-group clearfix">
            <Container>
              <Row className="align-content-center">
                <Col>
                  <small className="text-muted">Current place</small>
                </Col>
                <Col>
                  <small className="text-muted">Birth place</small>
                </Col>
              </Row>
              <Row className="align-content-center">
                <Col>
                  <p>{user?.current_place}</p>
                </Col>
                <Col>
                  <p>{user?.birth_place}</p>
                </Col>
              </Row>

              <Row className="align-content-center">
                <Col>
                  <small className="text-muted">Gender</small>
                </Col>
                <Col>
                  <small className="text-muted">Active</small>
                </Col>
              </Row>
              <Row className="align-content-center">
                <Col>
                  <p>{user?.gender}</p>
                </Col>
                <Col>
                  <p>{user?.active ? "Yes" : "No"}</p>
                </Col>
              </Row>

              <Row className="align-content-center">
                <Col>
                  <small className="text-muted">Registered on</small>
                </Col>
                <Col>
                  <small className="text-muted">Last update</small>
                </Col>
              </Row>
              <Row className="align-content-center">
                <Col>
                  <p>{user?.created_at}</p>
                </Col>
                <Col>
                  <p>{user?.updated_at}</p>
                </Col>
              </Row>
            </Container>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        centered
        show={showDeactivateModel}
        onHide={handleDeactivateModelClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {user?.active ? "Deactivate" : "Active"} {user?.first_name}{" "}
            {user?.last_name}.?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to {user?.active ? "deactivate" : "active"}{" "}
            {user?.first_name} {user?.last_name} account.?
          </p>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeactivateModelClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onDeativateUser}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        centered
        size="lg"
        show={showEditModel}
        onHide={handleEditModelClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Update {user?.first_name} {user?.last_name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <Row>
              <Col sm="4">
                <Form.Group>
                  <Form.Control
                    value={updateUser.first_name}
                    onChange={(e) => {
                      onUpdateChange({ first_name: e.target.value });
                    }}
                    type="text"
                    placeholder="First Name"
                  />
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group>
                  <Form.Control
                    onChange={(e) => {
                      onUpdateChange({ middle_name: e.target.value });
                    }}
                    value={updateUser.middle_name}
                    type="text"
                    placeholder="Middle Name"
                  />
                </Form.Group>
              </Col>
              <Col sm="4">
                <Form.Group>
                  <Form.Control
                    onChange={(e) => {
                      onUpdateChange({ last_name: e.target.value });
                    }}
                    value={updateUser.last_name}
                    type="text"
                    placeholder="Last Name"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <Form.Group>
                  <Form.Control
                    value={updateUser.email}
                    onChange={(e) => {
                      onUpdateChange({ email: e.target.value });
                    }}
                    type="email"
                    placeholder="Email"
                  />
                </Form.Group>
              </Col>
              <Col sm="6">
                <Form.Group>
                  <Form.Control
                    value={updateUser.gender}
                    onChange={(e) => {
                      onUpdateChange({ gender: e.target.value });
                    }}
                    type="text"
                    placeholder="Gender"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <Form.Group>
                  <Form.Control
                    value={updateUser.birth_date}
                    onChange={(e) => {
                      onUpdateChange({ birth_date: e.target.value });
                    }}
                    type="text"
                    placeholder="Birth Date"
                  />
                </Form.Group>
              </Col>
              <Col sm="6">
                <Form.Group>
                  <Form.Control
                    onChange={(e) => {
                      onUpdateChange({ birth_time: e.target.value });
                    }}
                    value={updateUser.birth_time}
                    type="text"
                    placeholder="Birth Time"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <Form.Group>
                  <Form.Control
                    onChange={(e) => {
                      onUpdateChange({ birth_place: e.target.value });
                    }}
                    value={updateUser.birth_place}
                    type="text"
                    placeholder="Birth Place"
                  />
                </Form.Group>
              </Col>
              <Col sm="6">
                <Form.Group>
                  <Form.Control
                    onChange={(e) => {
                      onUpdateChange({ current_place: e.target.value });
                    }}
                    value={updateUser.current_place}
                    type="text"
                    placeholder="Current Place"
                  />
                </Form.Group>
              </Col>
            </Row>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEditModelClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onUpdateUser}>
            Save change
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
export default BackendLayout(Users);
