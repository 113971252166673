import axios from "axios";
import { getUser } from "../../utils/utils";
import { API_ENDPOINTS } from "./api.constants";

export const getAPICall = async (endpoint, params, isBlob = false) => {
  var user = getUser();
  try {
    const response = await axios.get(endpoint, {
      params: params,
      responseType: isBlob ? "blob" : "json",
      headers: {
        "api-key": API_ENDPOINTS.APIKEY,
        Authorization: user ? `Bearer ${user.token}` : "",
      },
    });

    return { data: response.data };
  } catch (e) {
    return { data: e.response.data };
  }
};

export const deleteAPICall = async (endpoint, params) => {
  var user = getUser();
  try {
    const response = await axios.delete(endpoint, {
      params: params,
      headers: {
        "api-key": API_ENDPOINTS.APIKEY,
        Authorization: user ? `Bearer ${user.token}` : "",
      },
    });

    return { data: response.data };
  } catch (e) {
    return { data: e.response.data };
  }
};

export const postAPICall = async (endpoint, params, formdata = false) => {
  var user = getUser();
  var body = new FormData();
  if (formdata) {
    Object.keys(params).forEach((key) => {
      body.append(key, params[key]);
    });
  }
  try {
    const response = await axios.post(
      endpoint,
      formdata ? body : JSON.stringify(params),
      {
        headers: {
          "api-key": API_ENDPOINTS.APIKEY,
          "Content-Type": formdata ? "" : "application/json",
          Authorization: user ? `Bearer ${user.token}` : "",
        },
      }
    );
    return { data: response.data };
  } catch (e) {
    return { data: e.response.data };
  }
};
