import React, { useEffect, useState, useRef } from "react";
import "./index.scss";
import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Pagination,
  Modal,
  InputGroup,
  Form,
  Image,
} from "react-bootstrap";
import BackendLayout from "../../layouts/backend-layout";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import {
  getBlogsAsync,
  blogResponse,
  addBlogAsync,
  blogAddResponse,
  updateBlogAsync,
  blogUpdateResponse,
  deleteBlogsAync,
  blogDeleteResponse,
  resetAsync,
} from "../../api/slices/cms.slice";
import { toast } from "react-toastify";
import { API_ENDPOINTS } from "../../api/client/api.constants";
import JoditEditor from "jodit-react";

function Blogs(props) {
  const { setLoading } = props;
  const dispatch = useDispatch();

  const _blogResponse = useSelector(blogResponse);
  const _blogUpdateResponse = useSelector(blogUpdateResponse);
  const _blogDeleteResponse = useSelector(blogDeleteResponse);
  const _blogAddResponse = useSelector(blogAddResponse);

  const [index, setIndex] = useState();
  const [page, setPage] = useState({
    page: 1,
    page_size: 15,
  });
  const [blog, setBlog] = useState("");
  const [order_by, setOrderBy] = useState("DESC");
  const [query, setQuery] = useState();
  const [blogs, setBlogs] = useState([]);
  const [paging, setPaging] = useState([]);

  const [showAddModel, setShowAddModel] = useState(false);
  const handleAddModelClose = () => {
    setShowAddModel(false);
  };
  const handleAddModelShow = () => {
    setBlog({});
    setShowAddModel(true);
  };

  const [showEditModel, setShowEditModel] = useState(false);
  const handleEditModelClose = () => {
    setShowEditModel(false);
  };
  const handleEditModelShow = (index) => {
    setIndex(index);
    setBlog(blogs[index]);
    setShowEditModel(true);
  };

  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const handleDeleteModelClose = () => {
    setShowDeleteModel(false);
  };
  const handleDeleteModelShow = (index) => {
    setIndex(index);
    setShowDeleteModel(true);
  };

  const hiddenFileInput = useRef(null);
  const editor = useRef(null);
  const config = {
    readonly: false,
    askBeforePasteFromWord: false,
    askBeforePasteHTML: false

  };

  const columns = [
    { key: "id", value: "ID" },
    { key: "title", value: "Title" },
    { key: "cover", value: "Cover" },
    { key: "created_at", value: "Created At" },
    { key: "active", value: "Active" },
    { key: "action", value: "Action" },
  ];

  useEffect(() => {
    dispatch(getBlogsAsync({ page: page.page, page_size: page.page_size }));
  }, [page]);

  useEffect(() => {
    setLoading(_blogResponse.isLoading);
    if (_blogResponse.response && _blogResponse.response.status === 200) {
      window.scrollTo(0, 0);
      setBlogs(_blogResponse.response.data.blogs);
      setPaging(_blogResponse.response.data.paging);
    } else {
      toast.error(_blogResponse.response?.message);
    }
  }, [_blogResponse]);

  useEffect(() => {
    setLoading(_blogUpdateResponse.isLoading);
    if (
      _blogUpdateResponse.response &&
      _blogUpdateResponse.response.status === 200
    ) {
      setBlog({});
      dispatch(getBlogsAsync({ page: page.page, page_size: page.page_size }));
    } else {
      toast.error(_blogUpdateResponse.response?.message);
    }
  }, [_blogUpdateResponse]);

  useEffect(() => {
    setLoading(_blogAddResponse.isLoading);
    if (_blogAddResponse.response && _blogAddResponse.response.status === 200) {
      setBlog({});
      dispatch(getBlogsAsync({ page: page.page, page_size: page.page_size }));
    } else {
      toast.error(_blogAddResponse.response?.message);
    }
  }, [_blogAddResponse]);

  useEffect(() => {
    setLoading(_blogDeleteResponse.isLoading);
    if (
      _blogDeleteResponse.response &&
      _blogDeleteResponse.response.status === 200
    ) {
      setBlog({});
      dispatch(getBlogsAsync({ page: page.page, page_size: page.page_size }));
    } else {
      toast.error(_blogDeleteResponse.response?.message);
    }
  }, [_blogDeleteResponse]);

  const onPageChange = (i) => {
    setPage({ ...page, page: i });
  };

  const onOrderClick = (key) => {
    const ignoreKey = ["action", "updated_at"];
    if (ignoreKey.includes(key)) {
      return;
    }
    const order = order_by == "DESC" ? "ASC" : "DESC";
    setOrderBy(order);
    dispatch(
      getBlogsAsync({
        page: page.page,
        page_size: page.page_size,
        order_by: key,
        order,
      })
    );
  };

  const onQueryChange = (e) => {
    setQuery(e.target.value);
    if (e.target.value == "") {
      dispatch(
        getBlogsAsync({
          page: page.page,
          page_size: page.page_size,
        })
      );
    }
  };

  const onSearch = () => {
    dispatch(
      getBlogsAsync({
        page: page.page,
        page_size: page.page_size,
        search: query,
      })
    );
  };

  const onDelete = () => {
    setShowDeleteModel(false);
    const data = {
      id: blogs[index].id,
    };
    dispatch(deleteBlogsAync(data));
  };

  const onAddBlog = () => {
    setShowAddModel(false);
    dispatch(addBlogAsync(blog));
  };

  const onEditBlog = () => {
    setShowEditModel(false);
    if (typeof blog.cover == "string") {
      delete blog.cover;
    }
    delete blog.created_at;
    dispatch(updateBlogAsync(blog));
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    setBlog({ ...blog, cover: fileUploaded });
  };

  const handleActiveChange = (index) => {
    setIndex(index);
    dispatch(
      updateBlogAsync({
        id: blogs[index].id,
        active: !blogs[index].active,
      })
    );
  };

  return (
    <React.Fragment>
      <Row>
        <Col md="12">
          <Card>
            <Card.Header>
              Blogs
              <p className="text-muted">All Blogs</p>
            </Card.Header>
            <Card.Body>
              <Row className="ml-1">
                <Col>
                  <span
                    onClick={handleAddModelShow}
                    to="#ecommerce-add"
                    className="btn btn-primary btn-rounded box-shadow btn-icon"
                  >
                    <i className="fa fa-plus" /> Add Blog
                  </span>
                </Col>
                <Col md="auto">
                  <div className="mb-2">
                    <InputGroup>
                      <Form.Control
                        value={query}
                        onChange={onQueryChange}
                        type="text"
                        placeholder="Search"
                      />
                      <div className="input-group-btn">
                        <Button variant="primary" onClick={onSearch}>
                          <i className="fa fa-search" />
                        </Button>
                      </div>
                    </InputGroup>
                  </div>
                </Col>
              </Row>

              <div className="table-responsive">
                <Table
                  id="datatable1"
                  striped="columns"
                  className="table table-striped table-hover mt-4"
                >
                  <thead>
                    <tr>
                      {columns.map((item) => {
                        return (
                          <th
                            role="button"
                            className="text-center"
                            onClick={() => onOrderClick(item.key)}
                          >
                            <Row className="justify-content-md-center ml-2 mr-2">
                              <strong>{item.value}</strong>
                            </Row>
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {blogs.map((item, i) => {
                      return (
                        <tr className="text-center">
                          <td>{item.id}</td>
                          <td>{item.title}</td>
                          <td>
                            <Image
                              fluid
                              width={50}
                              height={50}
                              alt="#"
                              src={`${API_ENDPOINTS.IMAGE}${item.cover}`}
                            />
                          </td>
                          <td>{item.created_at}</td>
                          <td>
                            <div className="custom-control custom-switch">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id={`switch_${i}`}
                                checked={item.active}
                                readOnly
                                onChange={() => {
                                  handleActiveChange(i);
                                }}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={`switch_${i}`}
                              />
                            </div>
                          </td>
                          <td nowrap="nowrap" className="text-center">
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditModelShow(i)}
                            >
                              <i className="fa fa-edit" />
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteModelShow(i)}
                            >
                              <i className="fa fa-trash" />
                            </Button>{" "}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
              <Pagination>
                {Array.from({ length: paging.total_pages }).map((item, i) => {
                  return (
                    <Pagination.Item
                      onClick={() => onPageChange(i + 1)}
                      key={i}
                      active={i + 1 === paging.currentPage}
                    >
                      {i + 1}
                    </Pagination.Item>
                  );
                })}
              </Pagination>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal size="lg" show={showEditModel} onHide={handleEditModelClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Blog</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group>
                <Form.Control
                  value={blog.title}
                  onChange={(e) => {
                    setBlog({ ...blog, title: e.target.value });
                  }}
                  type="text"
                  placeholder="Title"
                />

                <InputGroup style={{ marginTop: "15px" }} className="mb-3">
                  <Form.Control
                    placeholder="Cover Image"
                    disabled
                    value={
                      typeof blog.cover == "string"
                        ? blog.cover
                        : blog.cover?.name
                    }
                  />
                  <InputGroup.Append>
                    <Button onClick={handleClick} variant="outline-secondary">
                      Choose
                    </Button>
                  </InputGroup.Append>
                </InputGroup>

                <input
                  type="file"
                  onChange={handleChange}
                  ref={hiddenFileInput}
                  style={{ display: "none" }}
                />

                <div style={{ marginTop: "15px" }}>
                  <JoditEditor
                    ref={editor}
                    value={blog.content}
                    config={config}
                    tabIndex={1}
                    onBlur={(newContent) =>
                      setBlog({ ...blog, content: newContent })
                    }
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEditModelClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              if (!blog.title || blog.title == "") {
                toast.error("Please enter title");
              } else if (!blog.cover) {
                toast.error("Please select blog cover");
              } else if (!blog.content || blog.content == "") {
                toast.error("Please enter blog content");
              } else {
                onEditBlog();
              }
            }}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal size="lg" show={showAddModel} onHide={handleAddModelClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Blog</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group>
                <Form.Control
                  value={blog.title}
                  onChange={(e) => {
                    setBlog({ ...blog, title: e.target.value });
                  }}
                  type="text"
                  placeholder="Title"
                />

                <InputGroup style={{ marginTop: "15px" }} className="mb-3">
                  <Form.Control
                    disabled
                    placeholder="Cover Image"
                    value={blog.cover?.name}
                  />
                  <InputGroup.Append>
                    <Button onClick={handleClick} variant="outline-secondary">
                      Choose
                    </Button>
                  </InputGroup.Append>
                </InputGroup>

                <input
                  type="file"
                  onChange={handleChange}
                  ref={hiddenFileInput}
                  style={{ display: "none" }}
                />

                <div style={{ marginTop: "15px" }}>
                  <JoditEditor
                    ref={editor}
                    value={blog.content}
                    config={config}
                    tabIndex={1}
                    onBlur={(newContent) =>
                      setBlog({ ...blog, content: newContent })
                    }
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleAddModelClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              if (!blog.title || blog.title == "") {
                toast.error("Please enter title");
              } else if (!blog.cover) {
                toast.error("Please select blog cover");
              } else if (!blog.content || blog.content == "") {
                toast.error("Please enter blog content");
              } else {
                onAddBlog();
              }
            }}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeleteModel} onHide={handleDeleteModelClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete.?</p>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteModelClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={(e) => {
              onDelete();
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
export default BackendLayout(Blogs);
