import React, { useEffect, useState, useRef } from "react";
import JoditEditor from "jodit-react";
import "./index.scss";
import { Row, Col, Card, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import BackendLayout from "../../layouts/backend-layout";

import { useSelector, useDispatch } from "react-redux";
import {
  getTermsConditionsAsync,
  termsConditionsResponse,
  updateTermsConditionsAsync,
  updatetermsConditionsResponse,
  resetAsync,
} from "../../api/slices/cms.slice";
import { toast } from "react-toastify";

function TermsConditions(props) {
  const { setLoading } = props;
  const dispatch = useDispatch();
  const _termsConditionsResponse = useSelector(termsConditionsResponse);
  const _updatetermsConditionsResponse = useSelector(updatetermsConditionsResponse);

  const editor = useRef(null);
  const [content, setContent] = useState("");
  const config = {
    readonly: false,
  };

  useState(() => {
    dispatch(getTermsConditionsAsync({}));
  }, []);

  const onSave = () => {
    if (content) {
      dispatch(updateTermsConditionsAsync({ content }));
    }
  };

  useEffect(() => {
    setLoading(_termsConditionsResponse.isLoading);
    if (
      _termsConditionsResponse.response &&
      _termsConditionsResponse.response.status === 200
    ) {
      if (_termsConditionsResponse.response.data.terms) {
        setContent(_termsConditionsResponse.response.data.terms.content);
      }
    } else {
      toast.error(_termsConditionsResponse.response?.message);
    }
  }, [_termsConditionsResponse]);

  useEffect(() => {
    setLoading(_updatetermsConditionsResponse.isLoading);
    if (
      _updatetermsConditionsResponse.response &&
      _updatetermsConditionsResponse.response.status === 200
    ) {
      dispatch(getTermsConditionsAsync({}));
    } else {
      toast.error(_updatetermsConditionsResponse.response?.message);
    }
  }, [_updatetermsConditionsResponse]);

  return (
    <React.Fragment>
      <Row>
        <Col>
          <Card>
            <Card.Header>Terms And Conditions</Card.Header>
            <Card.Body>
              <form method="get" action className="form-horizontal">
                <Form.Group>
                  <JoditEditor
                    ref={editor}
                    value={content}
                    config={config}
                    tabIndex={1}
                    onBlur={(newContent) => setContent(newContent)}
                    //onChange={(newContent) => setContent(newContent)}
                  />
                </Form.Group>

                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    onSave();
                  }}
                  className="btn btn-success btn-icon"
                >
                  <i className="fa fa-floppy-o" />
                  Save
                </Link>
              </form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default BackendLayout(TermsConditions);
