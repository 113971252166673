import { createSlice } from "@reduxjs/toolkit";
import { API_ENDPOINTS } from "../client/api.constants";

import { postAPICall, getAPICall, deleteAPICall } from "../client/api.client";

const initialState = {
  requests: {
    isLoading: false,
  },
  update_requests: {
    isLoading: false,
  },
  plans: {
    isLoading: false,
  },
  addPlans: {
    isLoading: false,
  },
  updatePlan: {
    isLoading: false,
  },
  deletePlan: {
    isLoading: false,
  },
};

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    requests: (state, action) => {
      state.requests = action.payload;
    },
    update_requests: (state, action) => {
      state.update_requests = action.payload;
    },
    plans: (state, action) => {
      state.plans = action.payload;
    },
    addPlans: (state, action) => {
      state.addPlans = action.payload;
    },
    updatePlan: (state, action) => {
      state.updatePlan = action.payload;
    },
    deletePlan: (state, action) => {
      state.deletePlan = action.payload;
    },
    reset: (state, action) => {
      requests.requests = {
        isLoading: false,
      };
      state.update_request = {
        isLoading: false,
      };
      state.plans = {
        isLoading: false,
      };
      state.addPlans = {
        isLoading: false,
      };
      state.updatePlan = {
        isLoading: false,
      };
      state.deletePlan = {
        isLoading: false,
      };
    },
  },
});

export const getRequestsAsync = (params) => async (dispatch) => {
  try {
    dispatch(requests({ isLoading: true }));
    const result = await getAPICall(API_ENDPOINTS.SUBSCRIPTIONREQUESTS, params);
    dispatch(requests({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(requests({ isLoading: false }));
  }
};
export const updateRequestAsync = (params) => async (dispatch) => {
  try {
    dispatch(update_requests({ isLoading: true }));
    const result = await postAPICall(
      API_ENDPOINTS.UPDATESUBSCRIPTIONREQUEST,
      params
    );
    dispatch(update_requests({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(update_requests({ isLoading: false }));
  }
};

export const getPlansAsync = (params) => async (dispatch) => {
  try {
    dispatch(plans({ isLoading: true }));
    const result = await getAPICall(API_ENDPOINTS.GETPLANS, params);
    dispatch(plans({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(plans({ isLoading: false }));
  }
};
export const addPlansAsync = (params) => async (dispatch) => {
  try {
    dispatch(addPlans({ isLoading: true }));
    const result = await postAPICall(API_ENDPOINTS.ADDPLANS, params);
    dispatch(addPlans({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(addPlans({ isLoading: false }));
  }
};
export const updatePlansAsync = (params) => async (dispatch) => {
  try {
    dispatch(updatePlan({ isLoading: true }));
    const result = await postAPICall(API_ENDPOINTS.UPDATEPLAN, params);
    dispatch(updatePlan({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(updatePlan({ isLoading: false }));
  }
};
export const deletePlansAsync = (params) => async (dispatch) => {
  try {
    dispatch(deletePlan({ isLoading: true }));
    const result = await deleteAPICall(API_ENDPOINTS.DELETEPLAN, params);
    dispatch(deletePlan({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(deletePlan({ isLoading: false }));
  }
};

export const resetAsync = () => async (dispatch) => {
  dispatch(reset(null));
};

export const {
  requests,
  update_requests,
  updatePlan,
  plans,
  addPlans,
  deletePlan,
  reset,
} = subscriptionSlice.actions;
export const requestsResponse = (state) => state.subscription.requests;
export const plansResponse = (state) => state.subscription.plans;
export const addPlanResponse = (state) => state.subscription.addPlans;
export const deletePlanResponse = (state) => state.subscription.deletePlan;
export const updatePlanResponse = (state) => state.subscription.updatePlan;
export const updateRequestResponse = (state) =>
  state.subscription.update_requests;
export default subscriptionSlice.reducer;
