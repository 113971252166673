import React, { useEffect, useState } from "react";
import "./index.scss";
import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Pagination,
  Modal,
  InputGroup,
  Form,
  Image,
} from "react-bootstrap";
import BackendLayout from "../../layouts/backend-layout";

import { useSelector, useDispatch } from "react-redux";
import {
  getPlansAsync,
  plansResponse,
  addPlansAsync,
  addPlanResponse,
  updatePlansAsync,
  updatePlanResponse,
  deletePlansAsync,
  deletePlanResponse,
  resetAsync,
} from "../../api/slices/subscription.slice";
import { toast } from "react-toastify";

function SubscriptionPlans(props) {
  const { setLoading } = props;
  const dispatch = useDispatch();
  const _plansResponse = useSelector(plansResponse);
  const _addPlanResponse = useSelector(addPlanResponse);
  const _updatePlanResponse = useSelector(updatePlanResponse);
  const _deletePlanResponse = useSelector(deletePlanResponse);

  const [showAddModel, setShowAddModel] = React.useState(false);
  const handleAddModelClose = () => setShowAddModel(false);
  const handleAddModelShow = () => {
    setShowAddModel(true);
  };

  const [showUpdateModel, setShowUpdateModel] = useState(false);
  const handleUpdateModelClose = () => {
    setShowUpdateModel(false);
  };
  const handleUpdateModelShow = (plan) => {
    setPlan(plan);
    setBenifits(plan.content);
    setShowUpdateModel(true);
  };

  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const handleDeleteModelClose = () => {
    setShowDeleteModel(false);
  };
  const handleDeleteModelShow = (index) => {
    setIndex(index);
    setShowDeleteModel(true);
  };

  const [index, setIndex] = useState();
  const [page, setPage] = useState({
    page: 1,
    page_size: 15,
  });
  const [plan, setPlan] = useState({});
  const [order_by, setOrderBy] = useState("DESC");
  const [query, setQuery] = useState();
  const [plans, setPlans] = useState([]);
  const [paging, setPaging] = useState([]);
  const [benifits, setBenifits] = useState([
    {
      item: "",
    },
  ]);

  const columns = [
    { key: "id", value: "ID" },
    { key: "title", value: "Title" },
    { key: "price", value: "Price" },
    { key: "questions", value: "Questions" },
    { key: "group_session", value: "Group Session" },
    { key: "one_to_one_session", value: "One To One" },
    { key: "active", value: "Active" },
    { key: "is_full", value: "Full" },
    { key: "action", value: "Action" },
  ];

  useEffect(() => {
    dispatch(getPlansAsync({ page: page.page, page_size: page.page_size }));
  }, [page]);

  useEffect(() => {
    setLoading(_plansResponse.isLoading);
    if (_plansResponse.response && _plansResponse.response.status === 200) {
      window.scrollTo(0, 0);
      setPlans(_plansResponse.response.data.plans);
      setPaging(_plansResponse.response.data.paging);
    } else {
      toast.error(_plansResponse.response?.message);
    }
  }, [_plansResponse]);

  useEffect(() => {
    setLoading(_addPlanResponse.isLoading);
    if (_addPlanResponse.response && _addPlanResponse.response.status === 200) {
      window.scrollTo(0, 0);
      setPage({
        page: 1,
        page_size: 15,
      });
    } else {
      toast.error(_addPlanResponse.response?.message);
    }
  }, [_addPlanResponse]);

  useEffect(() => {
    setLoading(_updatePlanResponse.isLoading);
    if (
      _updatePlanResponse.response &&
      _updatePlanResponse.response.status === 200
    ) {
      setPlan({});
      setBenifits([
        {
          item: "",
        },
      ]);
      setPage({
        page: 1,
        page_size: 15,
      });
    } else {
      toast.error(_updatePlanResponse.response?.message);
    }
  }, [_updatePlanResponse]);

  useEffect(() => {
    setLoading(_deletePlanResponse.isLoading);
    if (
      _deletePlanResponse.response &&
      _deletePlanResponse.response.status === 200
    ) {
      setIndex();
      setPage({
        page: 1,
        page_size: 15,
      });
    } else {
      toast.error(_deletePlanResponse.response?.message);
    }
  }, [_deletePlanResponse]);

  const onPageChange = (i) => {
    setPage({ ...page, page: i });
  };

  const onOrderClick = (key) => {
    const ignoreKey = ["action", "created_at", "current_place"];
    if (ignoreKey.includes(key)) {
      return;
    }
    const order = order_by == "DESC" ? "ASC" : "DESC";
    setOrderBy(order);
    dispatch(
      getPlansAsync({
        page: page.page,
        page_size: page.page_size,
        order_by: key,
        order,
      })
    );
  };

  const onQueryChange = (e) => {
    setQuery(e.target.value);
    if (e.target.value == "") {
      dispatch(
        getPlansAsync({
          page: page.page,
          page_size: page.page_size,
        })
      );
    }
  };

  const onSearch = () => {
    dispatch(
      getPlansAsync({
        page: page.page,
        page_size: page.page_size,
        search: query,
      })
    );
  };

  const onAddPlan = () => {
    setShowAddModel(false);
    const data = {
      title: plan.title,
      price: plan.price,
      kyat_price: plan.kyat_price,
      questions: plan.questions,
      group_session: plan.group_session,
      one_to_one_session: plan.one_to_one_session,
      content: benifits,
    };
    dispatch(addPlansAsync(data));
  };

  const onUpdatePlan = () => {
    setShowUpdateModel(false);
    const data = {
      id: plan.id,
      title: plan.title,
      price: plan.price,
      kyat_price: plan.kyat_price,
      questions: plan.questions,
      group_session: plan.group_session,
      one_to_one_session: plan.one_to_one_session,
      content: benifits,
    };
    dispatch(updatePlansAsync(data));
  };

  const onDelete = () => {
    setShowDeleteModel(false);
    const data = {
      id: plans[index].id,
    };
    dispatch(deletePlansAsync(data));
  };

  const handleActiveChange = (index) => {
    dispatch(
      updatePlansAsync({
        id: plans[index].id,
        active: !plans[index].active,
      })
    );
  };

  const handleFullChange = (index) => {
    dispatch(
      updatePlansAsync({
        id: plans[index].id,
        is_full: !plans[index].is_full,
      })
    );
  };

  return (
    <React.Fragment>
      <Row>
        <Col md="12">
          <Card>
            <Card.Header>
              Plans
              <p className="text-muted">All Subscription Plans</p>
            </Card.Header>
            <Card.Body>
              <Row className="ml-1">
                <Col>
                  <span
                    onClick={handleAddModelShow}
                    to="#ecommerce-add"
                    className="btn btn-primary btn-rounded box-shadow btn-icon"
                  >
                    <i className="fa fa-plus" /> Add Plan
                  </span>
                </Col>
                <Col md="auto">
                  <div className="mb-2">
                    <InputGroup>
                      <Form.Control
                        value={query}
                        onChange={onQueryChange}
                        type="text"
                        placeholder="Search"
                      />
                      <div className="input-group-btn">
                        <Button variant="primary" onClick={onSearch}>
                          <i className="fa fa-search" />
                        </Button>
                      </div>
                    </InputGroup>
                  </div>
                </Col>
              </Row>

              <div className="table-responsive">
                <Table
                  id="datatable1"
                  striped="columns"
                  className="table table-striped table-hover mt-4"
                >
                  <thead>
                    <tr>
                      {columns.map((item) => {
                        return (
                          <th
                            role="button"
                            nowrap="nowrap"
                            className="text-center"
                            onClick={() => onOrderClick(item.key)}
                          >
                            <Row className="justify-content-md-center ml-2 mr-2">
                              <strong>{item.value}</strong>
                            </Row>
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {plans.map((item, i) => {
                      return (
                        <tr>
                          <td>{item.id}</td>
                          <td>{item.title}</td>
                          <td>{item.price}</td>
                          <td>{item.questions || 0}</td>
                          <td>{item.group_session ? "Yes" : "No"}</td>
                          <td>{item.one_to_one_session ? "Yes" : "No"}</td>
                          <td>
                            <div className="custom-control custom-switch">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id={`switch_1${i}`}
                                checked={item.active}
                                readOnly
                                onChange={() => {
                                  handleActiveChange(i);
                                }}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={`switch_1${i}`}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="custom-control custom-switch">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id={`switch_2${i}`}
                                checked={item.is_full}
                                readOnly
                                onChange={() => {
                                  handleFullChange(i);
                                }}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={`switch_2${i}`}
                              />
                            </div>
                          </td>
                          <td nowrap="nowrap" className="text-center">
                            <Button
                              variant="success"
                              onClick={() => {
                                handleUpdateModelShow(item);
                              }}
                              size="sm"
                            >
                              <i className="fa fa-edit" />
                            </Button>{" "}
                            <Button
                              onClick={() => handleDeleteModelShow(i)}
                              variant="danger"
                              size="sm"
                            >
                              <i className="fa fa-trash" />
                            </Button>{" "}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>

              <Pagination>
                {Array.from({ length: paging.total_pages }).map((item, i) => {
                  return (
                    <Pagination.Item
                      onClick={() => onPageChange(i + 1)}
                      key={i}
                      active={i + 1 === paging.currentPage}
                    >
                      {i + 1}
                    </Pagination.Item>
                  );
                })}
              </Pagination>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal show={showAddModel} onHide={handleAddModelClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group>
                <Form.Control
                  value={plan.title}
                  onChange={(e) => {
                    setPlan({ ...plan, title: e.target.value });
                  }}
                  type="text"
                  placeholder="Title"
                />

                <Form.Control
                  value={plan.price}
                  className="mt-3"
                  onChange={(e) => {
                    setPlan({ ...plan, price: e.target.value });
                  }}
                  type="number"
                  placeholder="Price"
                />

                <Form.Control
                  value={plan.kyat_price}
                  className="mt-3"
                  onChange={(e) => {
                    setPlan({ ...plan, kyat_price: e.target.value });
                  }}
                  type="number"
                  placeholder="Kyat Price"
                />

                <Form.Control
                  value={plan.questions}
                  className="mt-3"
                  onChange={(e) => {
                    setPlan({ ...plan, questions: e.target.value });
                  }}
                  type="number"
                  placeholder="Questions"
                />

                <Form.Control
                  as="select"
                  className="mt-3"
                  value={plan.group_session}
                  onChange={(e) => {
                    setPlan({ ...plan, group_session: e.target.value });
                  }}
                >
                  <option value={"PLACEHOLDER"}>Group Session</option>
                  <option value={true}> Yes</option>
                  <option value={false}> No</option>
                </Form.Control>

                <Form.Control
                  as="select"
                  className="mt-3"
                  value={plan.one_to_one_session}
                  onChange={(e) => {
                    setPlan({ ...plan, one_to_one_session: e.target.value });
                  }}
                >
                  <option value={"PLACEHOLDER"}>One To One Session</option>
                  <option value={true}> Yes</option>
                  <option value={false}> No</option>
                </Form.Control>

                {benifits.map((benifit, i) => {
                  return (
                    <Row className="align-items-end ml-0 mr-0">
                      <Form.Control
                        className="mt-3"
                        value={benifit.item}
                        onChange={(e) => {
                          const newValue = {
                            item: e.target.value,
                          };
                          setBenifits((data) => {
                            const updatedBenifits = [...data];
                            updatedBenifits[i] = {
                              ...updatedBenifits[i],
                              ...newValue,
                            };
                            return updatedBenifits;
                          });
                        }}
                        type="text"
                        placeholder={`Benifit ${i + 1}`}
                      />
                      {i > 0 && (
                        <Button
                          variant="link"
                          onClick={(e) => {
                            const array = benifits;
                            array.splice(i, 1);
                            setBenifits([...array]);
                          }}
                        >
                          Remove
                        </Button>
                      )}
                    </Row>
                  );
                })}

                <Row className="align-items-end">
                  <Button
                    variant="link"
                    onClick={(e) => {
                      if (benifits.length < 5) {
                        const benifit = [...benifits];
                        benifit.push({
                          item: "",
                        });
                        setBenifits(benifit);
                      } else {
                        e.preventDefault();
                        toast.error("You can add only up to 5 benifits");
                      }
                    }}
                  >
                    Add More Benifit
                  </Button>
                </Row>
              </Form.Group>
            </Col>
          </Row>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleAddModelClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              if (!plan.title || plan.title == "") {
                toast.error("Please enter plan title");
              } else if (!plan.price || plan.price == "") {
                toast.error("Please enter plan price");
              } else if (!plan.kyat_price || plan.kyat_price == "") {
                toast.error("Please enter plan kyat price");
              } else if (!plan.questions || plan.questions == "") {
                toast.error("Please select questions");
              } else if (!plan.group_session || plan.group_session == "") {
                toast.error("Please select group session");
              } else if (
                !plan.one_to_one_session ||
                plan.one_to_one_session == ""
              ) {
                toast.error("Please select one to one session");
              } else if (benifits.find((item) => item.item == "")) {
                toast.error("Please add benifit");
              } else {
                onAddPlan();
              }
            }}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showUpdateModel} onHide={handleUpdateModelClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group>
                <Form.Control
                  value={plan.title}
                  onChange={(e) => {
                    setPlan({ ...plan, title: e.target.value });
                  }}
                  type="text"
                  placeholder="Title"
                />

                <Form.Control
                  value={plan.price}
                  className="mt-3"
                  onChange={(e) => {
                    setPlan({ ...plan, price: e.target.value });
                  }}
                  type="number"
                  placeholder="Price"
                />

                <Form.Control
                  value={plan.kyat_price}
                  className="mt-3"
                  onChange={(e) => {
                    setPlan({ ...plan, kyat_price: e.target.value });
                  }}
                  type="number"
                  placeholder="Kyat Price"
                />

                <Form.Control
                  value={plan.questions}
                  className="mt-3"
                  onChange={(e) => {
                    setPlan({ ...plan, questions: e.target.value });
                  }}
                  type="number"
                  placeholder="Text Responses"
                />

                <Form.Control
                  as="select"
                  className="mt-3"
                  value={plan.group_session}
                  onChange={(e) => {
                    setPlan({ ...plan, group_session: e.target.value });
                  }}
                >
                  <option value={"PLACEHOLDER"}>Group Session</option>
                  <option value={true}> Yes</option>
                  <option value={false}> No</option>
                </Form.Control>

                <Form.Control
                  as="select"
                  className="mt-3"
                  value={plan.one_to_one_session}
                  onChange={(e) => {
                    setPlan({ ...plan, one_to_one_session: e.target.value });
                  }}
                >
                  <option value={"PLACEHOLDER"}>One To One Session</option>
                  <option value={true}> Yes</option>
                  <option value={false}> No</option>
                </Form.Control>

                {benifits.map((benifit, i) => {
                  return (
                    <Row className="align-items-end ml-0 mr-0">
                      <Form.Control
                        className="mt-3"
                        value={benifit.item}
                        onChange={(e) => {
                          const newValue = {
                            item: e.target.value,
                          };
                          setBenifits((data) => {
                            const updatedBenifits = [...data];
                            updatedBenifits[i] = {
                              ...updatedBenifits[i],
                              ...newValue,
                            };
                            return updatedBenifits;
                          });
                        }}
                        type="text"
                        placeholder={`Benifit ${i + 1}`}
                      />
                      {i > 0 && (
                        <Button
                          variant="link"
                          onClick={(e) => {
                            const array = benifits;
                            array.splice(i, 1);
                            setBenifits([...array]);
                          }}
                        >
                          Remove
                        </Button>
                      )}
                    </Row>
                  );
                })}

                <Row className="align-items-end">
                  <Button
                    variant="link"
                    onClick={(e) => {
                      if (benifits.length < 5) {
                        const benifit = [...benifits];
                        benifit.push({
                          item: "",
                        });
                        setBenifits(benifit);
                      } else {
                        e.preventDefault();
                        toast.error("You can add only up to 5 benifits");
                      }
                    }}
                  >
                    Add More Benifit
                  </Button>
                </Row>
              </Form.Group>
            </Col>
          </Row>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleUpdateModelClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              if (!plan.title || plan.title == "") {
                toast.error("Please enter plan title");
              } else if (!plan.price || plan.price == "") {
                toast.error("Please enter plan price");
              } else if (!plan.kyat_price || plan.kyat_price == "") {
                toast.error("Please enter plan kyat price");
              } else if (!plan.questions || plan.questions == "") {
                toast.error("Please select questions");
              } else if (plan.group_session == undefined) {
                toast.error("Please select group session");
              } else if (plan.one_to_one_session == undefined) {
                toast.error("Please select one to one session");
              } else if (benifits.find((item) => item.item == "")) {
                toast.error("Please add benifit");
              } else {
                onUpdatePlan();
              }
            }}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeleteModel} onHide={handleDeleteModelClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete.?</p>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteModelClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={(e) => {
              onDelete();
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
export default BackendLayout(SubscriptionPlans);
